import { Locale } from 'global/interfaces';
import React from 'react';
import useLanguageStore from 'store/language';
import getCurrentLocale from './get-current-locale';


const useLocale = () => {
  const { locale, setLocale } = useLanguageStore();
  const currentLocale = React.useMemo<Locale>(() => {
    const l = getCurrentLocale(locale);
    return l;
  }, [locale]);

  const rtlClassName = React.useMemo<string>(() => {
    if (currentLocale.direction === 'rtl') return 'rtl-rotate';
    return '';
  }, [currentLocale]);

  return { currentLocale, rtlClassName, setLocale, locale };
};

export default useLocale;
