import * as React from 'react';
import WalletButton from './WalletButton';
import { Grid, Typography } from '@mui/material';
import { metamask, walletConnect } from './wallets';
// import { useNavigate } from 'react-router-dom';
// import useQuery from 'hooks/useQuery';

interface MetaMaskButtonProps {
  chainId: string;
}

export const MetaMaskButton: React.FC<MetaMaskButtonProps> = ({ chainId }) => {
  // const connectToMetamask = useWeb3Store(state => state.connectToMetamask);

  // const navigate = useNavigate();

  // const { redirectTo } = useQuery();

  const detectCurrentProvider = async () => {
    // await connectToMetamask(chainId).then(() => {
    //   navigate(!!redirectTo ? redirectTo : paths.home);
    // });
  };

  return (
    <>
      <WalletButton size="large" onClick={detectCurrentProvider}>
        <Grid container alignItems="center">
          <Grid item>
            <img src={metamask('').icon} alt={metamask('').id} loading="lazy" />
          </Grid>
          <Grid item>
            <Typography component="span" variant="body1" fontWeight="bold">
              {metamask('').name}
            </Typography>
          </Grid>
        </Grid>
      </WalletButton>
    </>
  );
};

export const WalletConnectButton: React.FC = () => {
  return (
    <>
      <WalletButton size="large" disabled>
        <Grid container alignItems="center">
          <Grid item>
            <img
              style={{
                WebkitFilter: 'grayscale(100%)',
                filter: 'grayscale(100%)',
                opacity: 0.7,
              }}
              src={walletConnect.icon}
              alt={walletConnect.id}
              loading="lazy"
            />
          </Grid>
          <Grid item>
            <Typography component="span" variant="body1" fontWeight="bold">
              {walletConnect.name}
            </Typography>
          </Grid>
        </Grid>
      </WalletButton>
    </>
  );
};
