import { LinearProgress } from '@mui/material';
import React from 'react';
import useLoading from 'store/loading';

const Loading: React.FC = () => {
  const loading = useLoading(state => state.loading);

  return (
    <>
      {loading ? (
        <LinearProgress color="secondary" sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 2000 }} />
      ) : null}
    </>
  );
};

export default Loading;
