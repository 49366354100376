import * as React from 'react';
import { primaryColor } from 'themes';
import { LogOutIcon, CopyIcon } from 'assets/icons';
import { Menu, MenuItem, ListItemIcon, Typography, Grid, Avatar, LinearProgress, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import useNavigation from './use-navigations';
import { FormattedMessage, useIntl } from 'react-intl';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useAccount } from 'wagmi';
import message from 'helpers/message';
import formatAddress from 'utils/format-address';
import useProfileImage from 'hooks/useProfileImage';
import copyToClipBoard from 'utils/copy-to-clipboard';
import { PermissionItem } from 'global/enums';
import usePermission from 'store/permission';
import useDisconnect from 'hooks/useDisconnect';

const MenuNavigation: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { image } = useProfileImage();

  const { address } = useAccount();

  const { formatMessage } = useIntl();

  const successfulTitle = formatMessage({ id: 'Successful' });

  const coppiedSuccessfullyMessage = formatMessage({ id: 'Coppied-successfully.message' });

  const { menuNavigations } = useNavigation();

  const handleOpenProfilePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfilePopover = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const popoverID = isPopoverOpen ? 'profile-popover' : undefined;

  const { disconnect } = useDisconnect();

  const permission = usePermission(state => state.permission);

  const onDisconnectWallet = () => {
    disconnect();
  };

  return (
    <>
      <Typography
        aria-controls={isPopoverOpen ? popoverID : undefined}
        aria-haspopup="true"
        aria-expanded={isPopoverOpen ? 'true' : undefined}
        onClick={handleOpenProfilePopover}
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Avatar alt="Remy Sharp" src={image} />
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={{ color: 'secondary.main', cursor: 'pointer' }}>
              {formatAddress(address!)}
            </Typography>
          </Grid>
          <Grid item>
            <ExpandMoreRoundedIcon
              sx={{
                transition: '0.3s all',
                marginTop: '5px',
                color: '#5F5F5F',
                transform: `rotate(${isPopoverOpen ? '180' : '0'}deg) `,
              }}
              fontSize="small"
            />
          </Grid>
        </Grid>
      </Typography>
      <Menu
        anchorEl={anchorEl}
        id={popoverID}
        disableScrollLock
        open={isPopoverOpen}
        onClose={handleCloseProfilePopover}
        onClick={handleCloseProfilePopover}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuNavigations.map(item => {
          // const isActive = location.pathname === item!.to;
          const isActive = false;
          return (
            <Link key={item!.id} to={item!.disabled ? '#' : item!.to}>
              <MenuItem
                color="#3C3C3B"
                disabled={item!.disabled}
                sx={{
                  minWidth: 300,
                  background: isActive ? 'rgba(0, 0, 0, 0.02)' : '',
                  '&:after': {
                    content: "''",
                    display: isActive ? 'block' : 'none',
                    position: 'absolute',
                    left: 0,
                    top: '17%',
                    borderRadius: '0 50% 50% 0 ',
                    width: 4,
                    height: 25,
                    background: primaryColor,
                  },
                }}
              >
                <ListItemIcon>
                  {item!.icon}
                  {/* <AccountCircleRoundedIcon fontSize="small" /> */}
                </ListItemIcon>
                {item!.title}
              </MenuItem>
            </Link>
          );
        })}
        <MenuItem
          onClick={() => {
            copyToClipBoard(address!);
            message(successfulTitle, coppiedSuccessfullyMessage, 'success');
          }}
        >
          <ListItemIcon>
            <CopyIcon colorSetter={theme => theme.palette.primary.main} />
          </ListItemIcon>
          <FormattedMessage id="header.copy-address" />
        </MenuItem>
        <MenuItem onClick={onDisconnectWallet}>
          <ListItemIcon>
            <LogOutIcon colorSetter={theme => theme.palette.primary.main} />
          </ListItemIcon>
          <FormattedMessage id="header.logout" />
        </MenuItem>
        {(permission.isCourt === PermissionItem.UNKNOWN || permission.isValidator === PermissionItem.UNKNOWN) && (
          <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
            <LinearProgress color="primary" />
          </Box>
        )}
      </Menu>
    </>
  );
};

export default MenuNavigation;
