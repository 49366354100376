import { Locale } from 'global/interfaces';
import English from './en.json';
import { enUS, faIR, arSA } from '@mui/material/locale';
// import { ArabicIcon, EnglishIcon, PersianIcon } from 'assets/icons';

const locales: Locale[] = [
  {
    locale: 'en',
    messages: English,
    direction: 'ltr',
    title: 'English',
    iconSrc:'/images/flags/us.jpg',
    muiLocale: enUS,
    default: true,
  },
  {
    locale: 'fa',
    messages: English,
    direction: 'rtl',
    title: 'فارسی',
    iconSrc:'/images/flags/ir.jpg',
    muiLocale: faIR,
    default: false,
  },
  {
    locale: 'ar',
    messages: English,
    direction: 'rtl',
    title: 'العربی',
    iconSrc:'/images/flags/sa.jpg',
    muiLocale: arSA,
    default: false,
  },
];

export default locales;
