import { purple, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { ITheme } from '@pushprotocol/uiweb';
import { Locale } from 'global/interfaces';
import getCurrentLocale from 'locales/get-current-locale';
import React from 'react';
import useLanguageStore from 'store/language';

export const primaryColor = "#00306F";

export const primaryColor2 = purple['700'];

export const secondaryColor = blue['A200'];

export const ChatWidgetTheme: ITheme = {
  bgColorPrimary: '#fff',
  bgColorSecondary: secondaryColor,
  textColorPrimary: '#000',
  textColorSecondary: 'white',
  btnColorPrimary: primaryColor2,
  btnColorSecondary: primaryColor2,
  border: '1px solid #eee',
  borderRadius: '10px',
  moduleColor: '#fff',
};

export const mainBorderRadius = 5;

export const useTheme = () => {
  const locale = useLanguageStore(state => state.locale);

  // Language configs
  const currentLocale = React.useMemo<Locale>(() => {
    const l = getCurrentLocale(locale);
    return l;
  }, [locale]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
        },
        components: {
          MuiSkeleton: {
            defaultProps: {
              animation: 'wave',
            },
          },
        },
        direction: currentLocale.direction,
        typography: { fontFamily: 'Open Sans' },
        shape: { borderRadius: mainBorderRadius },
      }),
    [currentLocale]
  );
  return theme;
};
