import * as React from 'react';
import { Navigation } from 'global/interfaces';
import { FormattedMessage } from 'react-intl';
import paths from 'router/paths';
import {
  EmptyWalletIcon,
  ChainLinkIcon,
  ElementGridIcon,
  ArrowRightIcon,
  ProfileIcon,
  DocumentSketchIcon,
  BookIcon,
  DocumentIcon,
} from 'assets/icons';
import usePermission from 'store/permission';
import { PermissionItem } from 'global/enums';
import { useAccount } from 'wagmi';

export const connectWallet: Navigation = {
  id: 'connect-wallet',
  to: paths.connect,
  title: React.createElement(FormattedMessage, { id: 'header.connect-wallet' }),
  icon: React.createElement(EmptyWalletIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const disconnectWallet: Navigation = {
  id: 'disconnect',
  to: paths.connect,
  title: React.createElement(FormattedMessage, { id: 'header.disconnect' }),
  icon: React.createElement(ArrowRightIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const categories: Navigation = {
  id: 'categories',
  to: paths.categories,
  title: React.createElement(FormattedMessage, { id: 'header.categories' }),
  icon: React.createElement(ElementGridIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const whitepaper: Navigation = {
  id: 'whitepaper',
  to: '/whitepaper.pdf',
  newTab: true,
  title: React.createElement(FormattedMessage, { id: 'header.whitepaper' }),
  icon: React.createElement(DocumentSketchIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const profile: Navigation = {
  id: 'profile',
  to: paths.userProfile,
  title: React.createElement(FormattedMessage, { id: 'header.profile' }),
  icon: React.createElement(ProfileIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const allNFTs: Navigation = {
  id: 'all-NFTs',
  to: paths.allNFTs,
  title: React.createElement(FormattedMessage, { id: 'header.all-NFTs' }),
  icon: React.createElement(ChainLinkIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const aboutUs: Navigation = {
  id: 'about-us',
  to: paths.aboutUs,
  title: React.createElement(FormattedMessage, { id: 'header.about-us' }),
  icon: React.createElement(BookIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const courtPanel: Navigation = {
  id: 'court-panel',
  to: paths.court,
  title: React.createElement(FormattedMessage, { id: 'header.court-panel' }),
  icon: React.createElement(BookIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};
export const daoPanel: Navigation = {
  id: 'dao-panel',
  to: paths.court,
  // disabled:true,
  title: React.createElement(FormattedMessage, { id: 'header.daoDashboard' }),
  icon: React.createElement(DocumentIcon, { width: '1.2rem', height: '1.1rem' }),
};

export const validatorPanel: Navigation = {
  id: 'validator-panel',
  to: paths.validatorProfile,
  title: React.createElement(FormattedMessage, { id: 'header.validator-panel' }),
  icon: React.createElement(BookIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
};

export const create: Navigation = {
  id: 'create',
  to: paths.createNFT,
  title: React.createElement(FormattedMessage, { id: 'header.create' }),
  icon: React.createElement(BookIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
  className: 'step2'
};
export const createDropDown: Navigation = {
  id: 'create',
  to: paths.createNFT,
  items: [
    {
      title: React.createElement(FormattedMessage, { id: 'header.create-gemstone' }),
      link: paths.createNFT,
    },
    {
      title: React.createElement(FormattedMessage, { id: 'header.create-collection' }),
      link: paths.createCollection,
    }
  ],
  title: React.createElement(FormattedMessage, { id: 'header.create' }),
  icon: React.createElement(BookIcon, { colorSetter: theme => theme.palette.primary.main, size: '1.2rem' }),
  className: 'step2'
};
const useNavigation = () => {
  const { address } = useAccount();
  const permission = usePermission(state => state.permission);
  return {
    menuNavigations: [
      profile,
      !!address && permission.isValidator === PermissionItem.HASPERMISSION ? validatorPanel : undefined,
      !!address && permission.isCourt === PermissionItem.HASPERMISSION ? courtPanel : undefined,
    ].filter(n => !!n),
    desktopNavigations: [
      createDropDown,
      categories,
      whitepaper,
      allNFTs,
      aboutUs,
      // !!address && permission.isCourt === PermissionItem.HASPERMISSION ? courtPanel : undefined,
      // !!address && permission.isValidator === PermissionItem.HASPERMISSION ? validatorPanel : undefined,
    ].filter(n => !!n),
    mobileNavigations: [
      create,
      categories,
      whitepaper,
      profile,
      allNFTs,
      aboutUs,
      !!address && permission.isCourt === PermissionItem.HASPERMISSION ? courtPanel : undefined,
      !!address && permission.isValidator === PermissionItem.HASPERMISSION ? validatorPanel : undefined,
    ].filter(n => !!n),
  };
};

export default useNavigation;
