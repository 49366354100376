import create from 'zustand';
import { LocaleType } from 'global/types';
import locales from 'locales';
import { createJSONStorage, persist } from 'zustand/middleware';

interface State {
  locale: LocaleType;
  setLocale: (locale: LocaleType) => void;
}

const defaultLocale = locales.find(l => l.default)!;

const useLanguageStore = create(
  persist<State>(
    (set, get) => ({
      locale: defaultLocale.locale,
      setLocale: locale => set({ locale }),
    }),
    {
      name: 'language',
      storage: createJSONStorage(() => localStorage),
    }
  )
);


export default useLanguageStore;
