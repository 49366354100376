export const ipfsBase = 'https://luxrare.infura-ipfs.io/ipfs';

export const lastestNFTCount = 8;

export const messagesCount = 10;

export const zeroAddress = '0x0000000000000000000000000000000000000000';

export const zeroPrice = '0.0';

export const courtePageQuery = 'page';

export const courteTabQuery = 'tab';

export const validatorTabQuery = 'tab';
export const parentTabQuery = 'parentTab';

export const proposalTabQuery = 'p';

export const customCurrency = 'MATIC';

export const sampleCollection = '/images/temp/sample-collection.jpg';

export const serviceFee = 2.4;

export const retryFetchCounts = 4;
