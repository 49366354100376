import { ProposalObj } from 'global/interfaces';

const proposalDataMap = (id: string, proposal: any[]): ProposalObj => ({
  descriptionCID: proposal[0],
  type: proposal[1],
  requester: proposal[2],
  endTime: Number(proposal[3].toString()) * 1000,
  quorom: Number(proposal[4].toString()),
  yesVotes: Number(proposal[5].toString()),
  noVotes: Number(proposal[6].toString()),
  description: null,
  forAddress: '',
  isExecuted: false,
  isConfirmed: false,
  id,
});

export default proposalDataMap;
