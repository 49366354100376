import axios from 'axios';
import { ipfsBase } from 'constants/defaults';
import { FormattedContractData } from 'global/interfaces';

const readItemsFromIPFS = (URI: FormattedContractData['tokenURI']): Promise<Record<string, any>> =>
  axios
    .get(`${ipfsBase}/${URI}`)
    .then((res) => {
      return res.data;
    })
    .catch(() => null);

export default readItemsFromIPFS;
