import React, { useEffect } from 'react';
import validChains from 'constants/valid-chains';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';

import { Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { OPENLOGIN_NETWORK_TYPE, OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { publicProvider } from 'wagmi/providers/public';
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector } from 'wagmi/connectors/injected';
import process from 'process';

type Props = {
  children: React.ReactNode;
};
const metadata = {
  name: 'Luxrare',
  description: 'The future of exchanging Gemstones in trustless ecosystem',
  url: 'https://Luxrare.io',
  icons: ['https://luxrare.io/images/logo-dark.svg'],
};

const projectId = 'e932b744b991639bd1c106418124bf9d';

const chainConfig = {
  chainNamespace: 'eip155',
  chainId: '0x' + validChains[0].id.toString(16),
  rpcTarget: validChains[0].rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
  displayName: validChains[0].name,
  tickerName: validChains[0].nativeCurrency?.name,
  ticker: validChains[0].nativeCurrency?.symbol,
  blockExplorer: validChains[0]?.blockExplorers.default?.url,
};

const web3AuthInstance = new Web3AuthNoModal({
  clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID!,
  chainConfig: chainConfig as any,
  web3AuthNetwork: process.env.REACT_APP_WEB3AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE,
});

const { publicClient } = configureChains(validChains, [
  jsonRpcProvider({
    rpc: () => ({
      http: process.env.REACT_APP_POLYGON_RPC as string,
    }),
  }),
  walletConnectProvider({ projectId }),
  publicProvider(),
]);

// Add openlogin adapter
const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
const openloginAdapterInstance = new OpenloginAdapter({
  privateKeyProvider,
  adapterSettings: {
    network: process.env.REACT_APP_WEB3AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE,
    uxMode: 'popup',
  },
});
web3AuthInstance.configureAdapter(openloginAdapterInstance);

const web3AuthConnectors = [
  new Web3AuthConnector({
    chains: validChains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: 'google',
      },
    },
  }),
  new Web3AuthConnector({
    chains: validChains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: 'discord',
      },
    },
  }),
  new Web3AuthConnector({
    chains: validChains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: 'facebook',
      },
    },
  }),
];

const wagmiConfig = createConfig({
  autoConnect: true,
  persister: null,
  connectors: [
    ...web3AuthConnectors,
    new WalletConnectConnector({ chains: validChains, options: { projectId, showQrModal: false, metadata } }),
    new InjectedConnector({ chains: validChains, options: { name: 'MetaMask' } }),
    new CoinbaseWalletConnector({
      chains: validChains,
      options: {
        appName: 'wagmi',
      },
    }),
  ],
  publicClient,
});
const web3ModalWagmiConfig = createConfig({
  autoConnect: true,
  persister: null,
  connectors: [
    new WalletConnectConnector({ chains: validChains, options: { projectId, showQrModal: false, metadata } }),
    new InjectedConnector({ chains: validChains, options: { name: 'MetaMask' } }),
  ],
  publicClient,
});

createWeb3Modal({ wagmiConfig: web3ModalWagmiConfig, projectId, chains: validChains, excludeWalletIds: ['web3auth'] });

const WagmiContainer = (props: Props) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      {props.children}
      {/* <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={validChains[0]}
        themeVariables={{
          '--w3m-accent-color': primaryColor,
          '--w3m-z-index': '5000',
          '--w3m-background-color': secondaryColor,
        }}
      /> */}
    </WagmiConfig>
  );
};

export default WagmiContainer;