interface Replacor {
  replace: string;
  with: string;
}

const makeReplace = (url: string, replacor: Replacor): string =>
  url.replace(`/:${replacor.replace}`, `/${replacor.with}`);

export const replacePathsDynamicParts = (url: string, replacor: Replacor | Replacor[]): string => {
  if (Array.isArray(replacor)) {
    let newURL: string = url;
    for (let i = 0; i < replacor.length; i++) {
      newURL = makeReplace(newURL, replacor[i]);
    }
    return newURL;
  } else {
    return makeReplace(url, replacor);
  }
};
