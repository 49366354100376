import validChains from 'constants/valid-chains';
import { useAccount, useBalance as useWagmiBalance } from 'wagmi';

const useBalance = () => {
  const { address } = useAccount();

  return useWagmiBalance({
    address,
    watch: true,
    chainId: validChains[0].id,
  });
};

export default useBalance;
