import { BigNumber } from 'ethers';
import { ValidatorObj } from 'global/interfaces';

const validatorDataMap = (validator: any, address: string): ValidatorObj => ({
  address,
  CID: validator['ProfileURI'],
  proposalId: '' + Number(validator['JoinedProposalId']),
  isGolden: validator['isGolden'],
  certificatesItemIDs: validator['listOfItemIdCertificatesGenerated']
    .map((c: BigNumber) => Number(c))
    .filter((value: number, index: number, array: number[]) => array.indexOf(value) === index),
});

export default validatorDataMap;
