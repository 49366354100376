import axios from 'axios';
import { isMainNet } from 'constants/is-main-net';
import MainLayout from 'layouts/main';
import Error500 from 'pages/errors/500';
import { Component, ErrorInfo, ReactNode } from 'react';
import { browserName, browserVersion, isDesktop, isTablet, osName, osVersion } from 'react-device-detect';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  isSent: boolean;
  loading: boolean;
  error: string | undefined;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      isSent: false,
      loading: false,
      error: undefined,
    };
    this.ReportCrash = this.ReportCrash.bind(this);
    this.componentDidCatch = this.componentDidCatch.bind(this);
  }

  public static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV !== 'production') return;

    this.setState({
      error: error.message,
    });
    this.ReportCrash(error.message);
  }

  async ReportCrash(error: string) {
    this.setState({
      loading: true,
    });
    await axios.post(process.env.REACT_APP_BOT_API_URL!, {
      body: `#CRASH 
🏳Network:${isMainNet ? 'MAINNET' : 'TESTNET'}
🔗PathName:${window.location.href}
💻Device:${isDesktop ? 'Desktop' : isTablet ? 'Tablet' : 'Mobile'} ${osName}-${osVersion}
🌐Browser: ${browserName}-v${browserVersion}
🪲Error: ${this.state?.error || error}`,
    });

    this.setState({
      loading: false,
      isSent: true,
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <MainLayout hasCustomHeader hasFooter hasHeader>
          <Error500 />
        </MainLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
