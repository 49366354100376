import { Box, Container } from '@mui/material'
import React from 'react'

type Props = {
    children: React.ReactNode;

}

const ErrorLayout = (props: Props) => {
    return (
        <Box
            sx={{
                background: 'url(/images/error-layout-bg.svg)',
                backgroundSize: { md: 'contain', xs: 'cover' },
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                


            }}
        >
            <Container sx={{display:'flex',minHeight: '90vh',alignItems:'center',justifyContent: 'center',flexDirection:'column'}}>
                {props.children}
            </Container>
        </Box>
    )
}

export default ErrorLayout