import cyrb53 from 'utils/cyrb53-hashing';
import { create } from 'zustand';

const defaultDebounceTime = 4000;

interface State {
  hashes: number[];
  debouncedSetAndRemoveHash: (str: string, options?: { debounceTime?: number }) => void;
  removeHash: <T extends string | number>(value: T) => void;
  isAvailableHash: (str: string) => boolean;
}

const useHash = create<State>()((set, get) => ({
  hashes: [],
  removeHash: value => {
    set({ hashes: get().hashes.filter(hash => hash !== (typeof value === 'number' ? value : cyrb53(value))) });
  },
  debouncedSetAndRemoveHash: (str, options) => {
    set({ hashes: [...get().hashes, cyrb53(str)] });
    setTimeout(() => {
      get().removeHash<string>(str);
    }, options?.debounceTime || defaultDebounceTime);
  },
  isAvailableHash: str => get().hashes.some(hash => hash === cyrb53(str)),
}));

export default useHash;
