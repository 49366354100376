import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { ReactComponent as Alert } from './svgs/alert.svg';
import { ReactComponent as ArrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as LogOut } from './svgs/logout.svg';
import { ReactComponent as ArrowLeft } from './svgs/arrow-left.svg';
import { ReactComponent as ArrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as ArrowUp } from './svgs/arrow-up.svg';
import { ReactComponent as ArrowSwapHorizontal } from './svgs/arrow-swap-horizontal.svg';
import { ReactComponent as Book } from './svgs/book.svg';
import { ReactComponent as ChainLink } from './svgs/chain-link.svg';
import { ReactComponent as Clock } from './svgs/clock.svg';
import { ReactComponent as Copy } from './svgs/copy.svg';
import { ReactComponent as Diagram } from './svgs/diagram.svg';
import { ReactComponent as Document } from './svgs/document.svg';
import { ReactComponent as DocumentSketch } from './svgs/document-sketch.svg';
import { ReactComponent as ElementGrid } from './svgs/element-grid.svg';
import { ReactComponent as EmptyWalletTick } from './svgs/empty-wallet-tick.svg';
import { ReactComponent as EmptyWallet } from './svgs/empty-wallet.svg';
import { ReactComponent as Wallet } from './svgs/wallet.svg';
import { ReactComponent as Discord } from './svgs/discord.svg';
import { ReactComponent as GalleryAdd } from './svgs/gallery-add.svg';
import { ReactComponent as Link } from './svgs/link.svg';
import { ReactComponent as People } from './svgs/people.svg';
import { ReactComponent as Profile } from './svgs/profile.svg';
import { ReactComponent as PercentageSquare } from './svgs/percentage-square.svg';
import { ReactComponent as ReceiptAdd } from './svgs/receipt-add.svg';
import { ReactComponent as MagicPen } from './svgs/magicpen.svg';
import { ReactComponent as Certificate } from './svgs/certificate.svg';
import { ReactComponent as Persian } from './svgs/ir.svg';
import { ReactComponent as English } from './svgs/us.svg';
import { ReactComponent as Arabic } from './svgs/sa.svg';
import { ReactComponent as AllProposal } from 'assets/icons/svgs/all-proposal.svg';
import { ReactComponent as TheiaProposal } from 'assets/icons/svgs/theia-proposal.svg';
import { ReactComponent as ParameterProposal } from 'assets/icons/svgs/parameter-proposal.svg';
import { ReactComponent as TitanList } from 'assets/icons/svgs/titan-list.svg';
import { ReactComponent as TheiaList } from 'assets/icons/svgs/theia-list.svg';
import { ReactComponent as Help } from 'assets/icons/svgs/help.svg';
import { ReactComponent as Exit } from 'assets/icons/svgs/exit.svg';

interface Props {
  sx?: (theme: any) => object;
  colorSetter?: (theme: any) => string;
  size?: React.CSSProperties['width'];
}

const getStyles = ({
  theme,
  colorSetter,
  size,
  sx,
}: React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
} & {
  theme?: Theme | undefined;
} & Props & {
    theme: Theme;
  }) => ({
  width: size ?? '1rem',
  height: size ?? '1rem',
  ...(sx?.(theme) ?? {}),
  '& *': {
    fill: colorSetter?.(theme),
  },
});

export const AlertIcon = styled(Alert)<Props>(props => getStyles(props));
export const ArrowDownIcon = styled(ArrowDown)<Props>(props => getStyles(props));
export const ArrowLeftIcon = styled(ArrowLeft)<Props>(props => getStyles(props));
export const ArrowRightIcon = styled(ArrowRight)<Props>(props => getStyles(props));
export const ArrowUpIcon = styled(ArrowUp)<Props>(props => getStyles(props));
export const ArrowSwapHorizontalIcon = styled(ArrowSwapHorizontal)<Props>(props => getStyles(props));
export const BookIcon = styled(Book)<Props>(props => getStyles(props));
export const ChainLinkIcon = styled(ChainLink)<Props>(props => getStyles(props));
export const ClockIcon = styled(Clock)<Props>(props => getStyles(props));
export const CopyIcon = styled(Copy)<Props>(props => getStyles(props));
export const DiagramIcon = styled(Diagram)<Props>(props => getStyles(props));
export const DocumentSketchIcon = styled(DocumentSketch)<Props>(props => getStyles(props));
export const ElementGridIcon = styled(ElementGrid)<Props>(props => getStyles(props));
export const EmptyWalletIcon = styled(EmptyWallet)<Props>(props => getStyles(props));
export const EmptyWalletTickIcon = styled(EmptyWalletTick)<Props>(props => getStyles(props));
export const GalleryAddIcon = styled(GalleryAdd)<Props>(props => getStyles(props));
export const LinkIcon = styled(Link)<Props>(props => getStyles(props));
export const PeopleIcon = styled(People)<Props>(props => getStyles(props));
export const ProfileIcon = styled(Profile)<Props>(props => getStyles(props));
export const PercentageSquareIcon = styled(PercentageSquare)<Props>(props => getStyles(props));
export const ReceiptAddIcon = styled(ReceiptAdd)<Props>(props => getStyles(props));
export const MagicPenIcon = styled(MagicPen)<Props>(props => getStyles(props));
export const LogOutIcon = styled(LogOut)<Props>(props => getStyles(props));
export const WalletIcon = styled(Wallet)<Props>(props => getStyles(props));
export const DocumentIcon = Document;
export const DiscordIcon = styled(Discord)<Props>(props => getStyles(props));
export const CertificateIcon = styled(Certificate)<Props>(props => getStyles(props));
export const ArabicIcon = styled(Arabic)<Props>(props => getStyles(props));
export const EnglishIcon = styled(English)<Props>(props => getStyles(props));
export const PersianIcon = styled(Persian)<Props>(props => getStyles(props));
export const AllProposalIcon = styled(AllProposal)<Props>(props => getStyles(props));
export const TheiaProposalIcon = styled(TheiaProposal)<Props>(props => getStyles(props));
export const ParameterProposalIcon = styled(ParameterProposal)<Props>(props => getStyles(props));
export const TitanListIcon = styled(TitanList)<Props>(props => getStyles(props));
export const TheiaListIcon = styled(TheiaList)<Props>(props => getStyles(props));
export const HelpIcon = styled(Help)<Props>(props => getStyles(props));
export const ExitIcon = styled(Exit)<Props>(props => getStyles(props));
