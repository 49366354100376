// import message from 'helpers/message';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'router/paths';
import { useDisconnect as useWagmiDisconnect } from 'wagmi';

const useDisconnect = () => {
  const { disconnect } = useWagmiDisconnect();
  const { formatMessage } = useIntl();
  const chatWidgetTitle = formatMessage({ id: 'chat-widget-title' });
  // const disconnectTitle = formatMessage({ id: 'header.disconnect.title' });
  // const disconnectMessage = formatMessage({ id: 'header.disconnect.message' });
  const navigate = useNavigate();
  const location = useLocation();
  return {
    disconnect: () => {
      const xpath = `//*[text()='${chatWidgetTitle}']`;
      (
        document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue
          ?.nextSibling as HTMLElement
      )?.click?.();
      disconnect();
      //   TODO: [temporary solution] - edit src/layouts/profile/index.tsx selectedAccount functionality and remove this if
      if (location.pathname.includes('profile')) {
        navigate(paths.home);
      }
    },
  };
};

export default useDisconnect;
