import { CourtObj } from 'global/interfaces';

const courtDataMap = (court: any, address: string): CourtObj => ({
  address,
  CID: court[0],
  proposalId: court[1]?.toString(),
  IPFSData: undefined,
});

export default courtDataMap;
