import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Box, IconButton } from '@mui/material';
import { messagesCount } from 'constants/defaults';
import { Transition } from 'react-transition-group';
import { TransitionStyles } from 'global/types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { mainBorderRadius, primaryColor } from 'themes';
import ConfigProvider from 'config/ConfigProvider';
import { Locale } from 'global/interfaces';
import getCurrentLocale from 'locales/get-current-locale';
import { useLocation } from 'react-router-dom';
import useLanguageStore from 'store/language';
import { IntlProvider } from 'react-intl';

interface MessageOptionsType {
  duration?: number;
}

type MessageType = (content?: string | React.ReactNode, options?: MessageOptionsType) => void;

const transitionDuration = 350;

const transitionStyles: TransitionStyles = {
  entering: { transform: 'translateY(-20px)', opacity: 0 },
  entered: { transform: 'translateY(0)', opacity: 1 },
  exiting: { transform: 'translateY(-20px)', opacity: 0 },
};

const EventMessage: React.FC<
  React.PropsWithChildren<{
    root?: any;
    options?: MessageOptionsType;
  }>
> = ({ children, root, options }) => {
  const [openTransition, setOpenTransition] = React.useState<boolean>(false);

  const locale = useLanguageStore(state => state.locale);
  // Language configs
  const currentLocale = React.useMemo<Locale>(() => {
    const l = getCurrentLocale(locale);
    return l;
  }, [locale]);


  const remove = React.useCallback((): void => {
    setOpenTransition(false);
    setTimeout(() => {
      root.unmount();
    }, transitionDuration);
  }, [root]);

  React.useEffect(() => {
    setOpenTransition(true);
    setTimeout(() => {
      remove();
    }, options?.duration ?? 20 * 1000);
  }, [remove, options?.duration]);

  return (
    <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages} onError={() => null}>
    <Transition
      in={openTransition}
      timeout={{
        enter: 10,
        exit: transitionDuration,
        appear: transitionDuration,
      }}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <Box
          style={{
            transitionDuration: `${transitionDuration}ms`,
            transitionProperty: 'all',
            transform: 'translateY(-20px)',
            opacity: 0,
            ...transitionStyles[state],
          }}
          className={`message-wrapper`}
          mt={1}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: `${mainBorderRadius}px`,
              boxShadow: '0 0 10px rgba(70, 70, 70, 0.2)',
            }}
            p={1}
          >
            <Box
              py={1}
              px={2}
              sx={{ borderRadius: `${mainBorderRadius}px`, border: `1px solid ${primaryColor}`, position: 'relative' }}
            >
              {children}
              <IconButton
                size="medium"
                sx={{
                  right: 0,
                  top: 0,
                  position: 'absolute',
                  background: `${primaryColor} !important`,
                  borderRadius: 0,
                  padding: 0,
                }}
                onClick={() => {
                  remove();
                }}
              >
                <Icon style={{ color: '#fff' }} path={mdiClose} size={0.6} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Transition>
    </IntlProvider>
  );
};

const eventMessage: MessageType = (content, options = {}) => {
  for (let i = 1; i <= messagesCount; i++) {
    if (!Boolean(document.querySelector(`#event-messages-content #message-${i}`)?.innerHTML)) {
      const root = ReactDOM.createRoot(document.querySelector(`#event-messages-content #message-${i}`)!);
      root.render(
        <React.StrictMode>
          <EventMessage root={root} options={options}>
            {content}
          </EventMessage>
        </React.StrictMode>
      );
      break;
    } else {
      continue;
    }
  }
};

export default eventMessage;
