import { Box } from '@mui/material';
import EventMessageContent from 'components/EventMessageContent';
import EventMessageTable from 'components/EventMessageTable';
import { customCurrency, zeroAddress, zeroPrice } from 'constants/defaults';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import { AuctionStatus, CertificateStatus, NFTStatus, OfferStatus } from 'global/enums';
import eventMessage from 'helpers/eventMessage';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'router/paths';
import useData from 'store/data';
import useLoading from 'store/loading';
import isEmptyObj from 'utils/is-empty-obj';
import { weiToETH } from 'utils/price';
import { replacePathsDynamicParts } from 'utils/url';
import { useAccount, usePublicClient, useContractEvent as wagmiUseContractEvent } from 'wagmi';
import { network } from 'constants/chains';
import useHash from 'store/hash';
import useFetch from './useFetch';

const useEvent = () => {
  const { address } = useAccount();
  const allNFTs = useData(state => state.allNFTs);
  const allRequestCertificates = useData(state => state.allRequestCertificates);
  const appendNFT = useData(state => state.appendNFT);
  const appendRequestCertificate = useData(state => state.appendRequestCertificate);
  const setLastestNFTsCount = useData(state => state.setLastestNFTsCount);
  const setLastestCollectionsCount = useData(state => state.setLastestCollectionsCount);
  const allCollections = useData(state => state.allCollections);
  const appendCollection = useData(state => state.appendCollection);
  const setEventLoading = useLoading(state => state.setEventLoading);
  const eventLoadingReal = useLoading(state => state.eventLoading);
  const debouncedSetAndRemoveHash = useHash(state => state.debouncedSetAndRemoveHash);
  const isAvailableHash = useHash(state => state.isAvailableHash);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const client = usePublicClient();
  const provider = new ethers.providers.Web3Provider(client as any);
  const firstRender = useRef(true);
  const { fetchNFT, getCertificatesList, fetchCollection } = useFetch();
  const eventLoading = useRef(false);
  const { createNFTValues } = locationState || {};
  const allRequestCertificatesRef = useRef(allRequestCertificates);
  const allNFTsRef = useRef(allNFTs);
  const allCollectionsRef = useRef(allCollections);
  const addressRef = useRef(address);
  const createNFTValuesRef = useRef({});
  useEffect(() => {
    if (createNFTValues) createNFTValuesRef.current = createNFTValues;
  }, [createNFTValues]);

  useEffect(() => {
    allCollectionsRef.current = allCollections;
  }, [allCollections]);
  useEffect(() => {
    addressRef.current = address;
  }, [address]);
  useEffect(() => {
    allRequestCertificatesRef.current = allRequestCertificates;
  }, [allRequestCertificates]);

  useEffect(() => {
    allNFTsRef.current = allNFTs;
  }, [allNFTs]);

  useEffect(() => {
    eventLoading.current = eventLoadingReal;
  }, [eventLoadingReal]);

  useEffect(() => {
    if (!firstRender.current) return;
    firstRender.current = false;
    initilizeEvents();
  }, []);

  const auctionSettedListener = useCallback(
    (
      itemId: BigNumber,
      ownerAddress: string,
      minPrice: BigNumberish,
      buyOutPrice: BigNumberish,
      bidIncreasePercentage: BigNumber,
      time: BigNumber
    ) => {
      const id = itemId.toNumber()! + '';
      const { data } = allNFTsRef.current[id] ?? {};
      const isEmpty = isEmptyObj(data);
      if (!isEmpty) {
        appendNFT(id, 'data', {
          ...data!,
          status: NFTStatus.AUCTION,
          auctionData: {
            bidIncreasePercentage: bidIncreasePercentage.toNumber(),
            buyNowPrice: weiToETH(buyOutPrice),
            endTime: time.toNumber() * 1000,
            minPrice: weiToETH(minPrice),
            NFTHighestBid: '0',
            NFTHighestBidder: zeroAddress,
            NFTSeller: ownerAddress,
            status: AuctionStatus.STARTED,
          },
        });
      }
      fetchNFT(id, { disableCache: true });
      if (ownerAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="auction-started" address={ownerAddress} tokenId={id} />
          </Box>
        );

        setEventLoading(false);
      }
    },
    [allNFTs, appendNFT, setEventLoading, addressRef.current]
  );

  const bidPlacedListener = useCallback(
    (itemId: BigNumber, bidder: string, bidValue: BigNumberish) => {
      const id = itemId.toNumber()! + '';
      const hashString = `${id},${bidder},${weiToETH(bidValue)}`;

      if (!isAvailableHash(hashString)) {
        const { bidHistory, data } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          const fifteenMinutes = 15 * 60 * 1000;
          const endTime = data?.auctionData?.endTime || 0;
          const isExtendable = endTime - Date.now() < fifteenMinutes;
          appendNFT(id, 'data', {
            ...data!,
            auctionData: {
              ...data?.auctionData!,
              status: AuctionStatus.ISGOINGON,
              NFTHighestBid: weiToETH(bidValue),
              NFTHighestBidder: bidder,
              endTime: isExtendable ? endTime + fifteenMinutes : endTime,
            },
          });
          appendNFT(id, 'bid', [...(bidHistory ?? []), { bid: weiToETH(bidValue), bidder: bidder, time: Date.now() }]);
          debouncedSetAndRemoveHash(hashString);
        }
        if (bidder === addressRef.current) {
          eventMessage(
            <>
              <Box mb={2}>
                <EventMessageContent type="bid-success" address={bidder} tokenId={id} />
              </Box>
              <EventMessageTable
                options={[{ title: 'Bid locked amount', value: `${weiToETH(bidValue)} ${customCurrency}` }]}
              />
            </>
          );
        }
        setEventLoading(false);
      }
    },
    [allNFTs, isAvailableHash, addressRef.current, appendNFT, debouncedSetAndRemoveHash, setEventLoading]
  );

  const auctionCancelledListener = useCallback(
    (itemId: BigNumber, ownerAddress: string, timeCanceled: BigNumber) => {
      const id = itemId.toNumber()! + '';
      const { data } = allNFTsRef.current[id] ?? {};
      const isEmpty = isEmptyObj(data);
      if (!isEmpty) {
        appendNFT(id, 'data', {
          ...data!,
          status: NFTStatus.NOTACTIVE,
          auctionData: undefined,
        });
        appendNFT(id, 'bid', []);
      }
      if (ownerAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="auction-cancel" address={ownerAddress} tokenId={id} />
          </Box>
        );

        setEventLoading(false);
      }
    },
    [allNFTs, appendNFT, addressRef.current, setEventLoading]
  );

  const offerWithdrawListener = useCallback(
    (itemId: BigNumber, offerAddress: string) => {
      const id = itemId.toNumber()! + '';
      const { offerList } = allNFTsRef.current[id] ?? {};

      if (offerList?.length) {
        appendNFT(
          id,
          'offer',
          offerList.filter(o => o.offerAddress !== offerAddress)
        );
      }
      if (offerAddress === addressRef.current) {
        offerList?.length && appendNFT(id, 'activeOffer', false);
        console.log('see?')
        fetchNFT(id, { disableCache: true });
        eventMessage(
          <Box>
            <EventMessageContent type="offer-withdraw" address={offerAddress} tokenId={id} />
          </Box>
        );
        setEventLoading(false);
      }
    },
    [allNFTs, addressRef.current, appendNFT, setEventLoading]
  );

  const auctionFinishedListener = useCallback(
    (itemId: BigNumber, finisher: string, ownerAddress: string, winnerAddress: string, bidValue: BigNumberish) => {
      const id = itemId.toNumber()! + '';
      const hashString = `${id},${finisher},${ownerAddress},${winnerAddress},${weiToETH(bidValue)}`;
      if (!isAvailableHash(hashString)) {
        const { data, priceHistory } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          appendNFT(id, 'data', {
            ...data!,
            status: NFTStatus.NOTACTIVE,
            auctionData: undefined,
            contractOwnerAddress: winnerAddress,
          });
          appendNFT(id, 'bid', []);
          appendNFT(id, 'price', [
            ...(priceHistory?.length ? priceHistory : []),
            { date: Date.now(), from: ownerAddress, to: winnerAddress, price: +weiToETH(bidValue) },
          ]);
          debouncedSetAndRemoveHash(hashString);
        }
        if (finisher === addressRef.current) {
          eventMessage(
            <Box>
              <EventMessageContent type="auction-finished" address={ownerAddress} tokenId={id} />
            </Box>
          );
        }
        setEventLoading(false);
      }
    },
    [isAvailableHash, allNFTs, addressRef.current, appendNFT, debouncedSetAndRemoveHash, setEventLoading]
  );

  const offerAddedListener = useCallback(
    (
      itemId: BigNumber,
      offerAddress: string,
      tokenAddress: string,
      price: BigNumberish,
      requestValidation: boolean
    ) => {
      const id = itemId.toNumber()! + '';
      const hashString = `${id},${offerAddress},${tokenAddress},${weiToETH(price)},${requestValidation}`;
      if (!isAvailableHash(hashString)) {
        const { data, offerList } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          appendNFT(id, 'offer', [
            ...(offerList ?? []),
            {
              price: +weiToETH(price),
              isAcceptedByOwner: false,
              offerAddress,
              tokenAddress,
              validatorRequest: requestValidation,
              date: Date.now(),
              status: OfferStatus.PENDING,
            },
          ]);
          debouncedSetAndRemoveHash(hashString);
        }

        if (offerAddress === addressRef.current) {
          !isEmpty && appendNFT(id, 'activeOffer', true);
          eventMessage(
            <Box>
              <EventMessageContent type="offer-added" address={offerAddress} tokenId={id} />
            </Box>
          );
        }
        setEventLoading(false);
      }
    },
    [isAvailableHash, allNFTsRef.current, addressRef.current, setEventLoading, appendNFT, debouncedSetAndRemoveHash]
  );

  const buyOutedListener = useCallback(
    (itemId: BigNumber, ownerAddress: string, buyerAddress: string, buyOutValue: BigNumberish) => {
      const id = itemId.toNumber()! + '';
      const hashString = `${id},${ownerAddress},${buyerAddress},${weiToETH(buyOutValue)}`;
      console.log(id,hashString)
      if (!isAvailableHash(hashString)) {
        const { data, priceHistory } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          appendNFT(id, 'data', {
            ...data!,
            status: NFTStatus.NOTACTIVE,
            auctionData: undefined,
            contractOwnerAddress: buyerAddress,
          });
          appendNFT(id, 'bid', []);
          appendNFT(id, 'price', [
            ...(priceHistory?.length ? priceHistory : []),
            { date: Date.now(), from: ownerAddress, to: buyerAddress, price: +weiToETH(buyOutValue) },
          ]);
          fetchNFT(id,{disableCache:true})
          debouncedSetAndRemoveHash(hashString);
        }
        if (buyerAddress === addressRef.current) {
          eventMessage(
            <>
              <Box mb={2}>
                <EventMessageContent type="buy-out" address={ownerAddress} tokenId={id} />
              </Box>
              <EventMessageTable
                options={[{ title: 'Amount paid', value: `${weiToETH(buyOutValue)} ${customCurrency}` }]}
              />
            </>
          );
        }
        setEventLoading(false);
      }
    },
    [isAvailableHash, allNFTs, addressRef.current, appendNFT, debouncedSetAndRemoveHash, setEventLoading]
  );

  const updatePriceListener = useCallback(
    (itemId: BigNumber, ownerAddress: string, price: BigNumberish, tokenAddress: string, time: BigNumberish) => {
      const id = itemId.toNumber()! + '';
      const { data } = allNFTsRef.current[id] ?? {};
      const updatedPrice = weiToETH(price);
      const isEmpty = isEmptyObj(data);
      if (!isEmpty) {
        appendNFT(id, 'data', {
          ...data!,
          price: updatedPrice,
          status: updatedPrice === zeroPrice ? NFTStatus.NOTACTIVE : NFTStatus.FIXPRICE,
          tokenERCAccepted: tokenAddress,
        });
      }
      if (ownerAddress === addressRef.current) {
        eventMessage(
          <>
            <Box mb={2}>
              <EventMessageContent
                type={updatedPrice === zeroPrice ? 'price-remove' : 'update-price'}
                address={ownerAddress}
                tokenId={id}
              />
            </Box>
          </>
        );
        navigate(replacePathsDynamicParts(paths.eachNFT, { replace: 'id', with: id }));
        setEventLoading(false);
      }
    },
    [allNFTs, appendNFT, setEventLoading, addressRef.current]
  );

  const fixPriceListener = useCallback(
    (itemId: BigNumber, ownerAddress: string, price: BigNumberish, tokenAddress: string, time: BigNumberish) => {
      const id = itemId.toNumber()! + '';
      const { data } = allNFTsRef.current[id] ?? {};
      const isEmpty = isEmptyObj(data);
      if (!isEmpty) {
        appendNFT(id, 'data', {
          ...data!,
          price: weiToETH(price),
          status: NFTStatus.FIXPRICE,
          tokenERCAccepted: tokenAddress,
        });
      }
      if (ownerAddress === addressRef.current) {
        eventMessage(
          <>
            <Box mb={2}>
              <EventMessageContent type="fix-price" address={ownerAddress} tokenId={id} />
            </Box>
            <EventMessageTable options={[{ title: 'Price', value: `${weiToETH(price)} ${customCurrency}` }]} />
          </>
        );
        navigate(replacePathsDynamicParts(paths.eachNFT, { replace: 'id', with: id }));
        setEventLoading(false);
      }
    },
    [allNFTs, appendNFT, setEventLoading, addressRef.current]
  );

  const buyNFTListener = useCallback(
    (itemId: BigNumber, sellerAddress: string, buyerAddress: string, price: BigNumberish, tokenAddress: string) => {
      const id = itemId.toNumber()! + '';
      const hashString = `${id},${sellerAddress},${buyerAddress},${weiToETH(price)},${tokenAddress}`;
      console.log(isAvailableHash(hashString), hashString, itemId, sellerAddress, buyerAddress, price, tokenAddress);
      if (!isAvailableHash(hashString)) {
        const { data, priceHistory } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          appendNFT(id, 'data', {
            ...data!,
            contractOwnerAddress: buyerAddress,
            price: zeroPrice,
            status: NFTStatus.NOTACTIVE,
            tokenERCAccepted: tokenAddress,
          });
          appendNFT(id, 'price', [
            ...(priceHistory?.length ? priceHistory : []),
            { date: Date.now(), from: sellerAddress, to: buyerAddress, price: +weiToETH(price) },
          ]);
          debouncedSetAndRemoveHash(hashString);
        }
        if (buyerAddress === addressRef.current) {
          eventMessage(
            <>
              <Box mb={2}>
                <EventMessageContent type="buy" address={sellerAddress} tokenId={id} />
              </Box>
              <EventMessageTable options={[{ title: 'Price', value: `${weiToETH(price)} ${customCurrency}` }]} />
            </>
          );
        }
        setEventLoading(false);
      }
    },
    [isAvailableHash, allNFTs, addressRef.current, appendNFT, debouncedSetAndRemoveHash, setEventLoading]
  );

  const offerAcceptedListener = useCallback(
    (
      itemId: BigNumber,
      ownerAddress: string,
      offerAddress: string,
      price: BigNumberish,
      tokenAddress: string,
      validator: string
    ) => {
      const id = itemId.toNumber()! + '';
      const hashString = `${id},${ownerAddress},${offerAddress},${weiToETH(price)}`;
      if (!isAvailableHash(hashString)) {
        const { data, priceHistory, offerList } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          appendNFT(id, 'data', {
            ...data!,
            status: NFTStatus.NOTACTIVE,
            contractOwnerAddress: validator === zeroAddress ? offerAddress : data?.contractOwnerAddress!,
          });
          appendNFT(id, 'price', [
            ...(priceHistory?.length ? priceHistory : []),
            { date: Date.now(), from: ownerAddress!, to: offerAddress, price: +weiToETH(price) },
          ]);
          const acceptedOffer = offerList?.map(o =>
            o.offerAddress === offerAddress ? { ...o, offerAddress: zeroAddress, price: 0.0 } : o
          );
          appendNFT(id, 'offer', [...(acceptedOffer ?? [])]);
          debouncedSetAndRemoveHash(hashString);
        }
        if (ownerAddress === addressRef.current) {
          !isEmpty && appendNFT(id, 'activeOffer', false);
          eventMessage(
            <Box>
              <EventMessageContent type="offer-accepted" address={offerAddress} tokenId={id} />
            </Box>
          );
        }
        setEventLoading(false);
      }
    },
    [isAvailableHash, allNFTs, addressRef.current, appendNFT, debouncedSetAndRemoveHash, setEventLoading]
  );

  const itemListedListener = useCallback(
    (itemId: BigNumber, creatorAddress: string, royaltyFee: BigNumberish) => {
      const id = itemId.toNumber()! + '';
      setLastestNFTsCount(+id);
      if (creatorAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="item-listed" address={creatorAddress} tokenId={id} />
          </Box>
        );
        eventLoading.current && navigate(replacePathsDynamicParts(paths.eachNFT, { replace: 'id', with: id }));
        setEventLoading(false);
      }
    },
    [setEventLoading, setLastestNFTsCount, navigate, addressRef.current, eventLoading.current]
  );

  const tokenMintedListener = useCallback(
    (collectionId: BigInt, itemId: BigNumber, ipfs: string, timestamp: BigInt, category: string) => {
      fetchCollection(collectionId?.toString(), { disableCache: true });
    },
    []
  );

  const submitRequestCertificateListener = useCallback(
    (
      itemId: BigNumber,
      ownerAddress: string,
      validatorAddress: string,
      makeOfferRequester: string,
      isGold: boolean,
      validatorFee: BigNumber,
      certificateId: BigNumber
    ) => {
      const id = itemId.toNumber()! + '';
      const requestCertificateId = certificateId.toString();
      const hashString = `${id},${ownerAddress},${validatorAddress},${makeOfferRequester},${isGold},${weiToETH(
        validatorFee
      )}`;
      if (!isAvailableHash(hashString)) {
        const { data } = allNFTsRef.current[id] ?? {};
        const isEmpty = isEmptyObj(data);
        if (!isEmpty) {
          appendNFT(id, 'data', {
            ...data!,
            status: NFTStatus.INPROGRESS,
          });
        }

        appendRequestCertificate(requestCertificateId, {
          addressOfMakeOfferRequester: zeroAddress,
          requester: ownerAddress,
          validator: validatorAddress,
          NFTID: id,
          isCanceled: false,
          isExecuted: false,
          needGoldenTick: isGold,
          requestTime: Date.now(),
          id: requestCertificateId,
          timeConfirmedByValidator: 0,
          validatorFee: weiToETH(validatorFee),
        });

        debouncedSetAndRemoveHash(hashString);
        if(validatorAddress === addressRef.current ){
          if(window.location.href.includes(paths.validatorProfile)){
            navigate(paths.validatorProfile+"?tab=pending")
          }
        }

        if (ownerAddress === addressRef.current) {
          eventMessage(
            <Box>
              <EventMessageContent type="submit-request-certifcate" address={validatorAddress} tokenId={id} />
            </Box>
          );
        }
        setEventLoading(false);
      }
    },
    [
      isAvailableHash,
      appendRequestCertificate,
      debouncedSetAndRemoveHash,
      addressRef.current,
      setEventLoading,
      appendNFT,
    ]
  );

  const cancelRequestToReceiveGemStone = useCallback(
    (owner: string, itemId: BigNumber) => {
      const id = itemId.toString();
      fetchNFT(id,{disableCache:true})
      if (owner === addressRef.current) {
        getCertificatesList(id, { disableCache: true });
        setEventLoading(false);
      }
    },
    [addressRef.current, eventLoading]
  );

  const acceptCertificateListener = useCallback(
    (certificateId: BigNumber, validatorAddress: string) => {
      const id = certificateId.toNumber()! + '';
      const data = allRequestCertificatesRef.current[id] ?? {};
      const isEmpty = isEmptyObj(data);
      if (!isEmpty) {
        appendRequestCertificate(id, { ...data!, timeConfirmedByValidator: Date.now() });
      }
      if (validatorAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="accept-certificate-by-validator" address={validatorAddress} tokenId={id} />
          </Box>
        );

        eventLoading.current &&
          navigate(
            {
              pathname: paths.validatorProfile,
              search: `?tab=pending`,
            },
            { replace: true }
          );
      }
      setEventLoading(false);
    },
    [addressRef.current, setEventLoading, appendRequestCertificate, navigate, eventLoading.current]
  );
  // fetchNFT('52').then((data)=>console.log(data))
  const GenerateCertificateSubmittedListener = useCallback(
    (
      certificateId: BigNumber,
      certificateGenerator: string,
      CID: string,
      isAcceptedByMe: boolean,
      isKeepWithMe: boolean,
      validator: string
    ) => {
      const id = certificateId.toNumber()! + '';
      const data = allRequestCertificatesRef.current[id] ?? {};
      const isEmpty = isEmptyObj(data);
      console.log(data);
      if (!isEmpty) {
        fetchNFT(data.NFTID, { disableCache: true });
        appendRequestCertificate(id, { ...data!, isExecuted: true });
        getCertificatesList(data.NFTID, { disableCache: true });
        console.log('done');
      }
      if (certificateGenerator === addressRef.current || validator === addressRef.current) {
        setEventLoading(false);
        navigate(
          replacePathsDynamicParts(paths.showCertificate, [
            { replace: 'id', with: '' + id },
          ])
        );
      }
    },
    [fetchNFT, addressRef.current, setEventLoading, navigate]
  );

  const requestReciveGemStoneListener = useCallback(
    (ownerAddress: string, validatorAddress: string, itemID: BigNumber) => {
      const id = itemID.toNumber()! + '';
      const data = allNFTsRef.current[id] ?? {};
      const isEmpty = isEmptyObj(data);
      fetchNFT(id,{disableCache:true})
      if (!isEmpty) {
        appendNFT(id, 'certificate-status', CertificateStatus.RequestReciveGemStone);
      }
      if (ownerAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent
              type="change-certificate-status"
              address={ownerAddress}
              secondaryAddress={validatorAddress}
              tokenId={id}
            />
          </Box>
        );

        setEventLoading(false);
      }
    },
    [allNFTs, addressRef.current, appendNFT, setEventLoading]
  );

  const updateNFTListener = useCallback(
    (itemId: BigNumber, ownerAddress: string, newURI: string) => {
      const id = itemId.toNumber()! + '';
      const { data } = allNFTsRef.current[id] ?? {};
      console.log(ownerAddress);
      if (ownerAddress === addressRef.current) {
        fetchNFT(id, { disableCache: true });
        eventMessage(
          <Box>
            <EventMessageContent type="update-NFT" />
          </Box>
        );
        eventLoading.current && navigate(replacePathsDynamicParts(paths.eachNFT, { replace: 'id', with: id }));
        setEventLoading(false);
      }
    },
    [allNFTs, addressRef.current, setEventLoading, appendNFT, navigate, eventLoading.current]
  );

  const validatorGiveGemtoOwnerSubmitedListener = useCallback(
    (validatorAddress: string, itemId: BigNumber) => {
      const id = itemId.toNumber()! + '';
      appendNFT(id, 'certificate-status', CertificateStatus.TransferNFTFromValidator);
      if (validatorAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="validator-give-gem" address={validatorAddress} tokenId={id} />
          </Box>
        );

        setEventLoading(false);
      }
    },
    [addressRef.current, setEventLoading, appendNFT]
  );

  const ownerReceivedGemstoneFromValidatorListener = useCallback(
    (validatorAddress: string, itemId: BigNumber) => {
      const id = itemId.toNumber()! + '';
      appendNFT(id, 'certificate-status', CertificateStatus.ConfirmationReceivingNFTByOwner);
      fetchNFT(id,{disableCache:true})
      getCertificatesList(id,{disableCache:true})
      if (validatorAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="owner-give-gem" address={validatorAddress} tokenId={id} />
          </Box>
        );

        setEventLoading(false);
      }
    },
    [addressRef.current, setEventLoading, appendNFT]
  );

  const collectionURIUpdatedListener = useCallback(
    (collectionID: BigNumber, creatorAddress: string, newURI: string) => {
      const id = collectionID.toNumber()! + '';
      const data = allCollectionsRef.current[id];
      if (data) {
        appendCollection(id, {
          ...data,
          collectionURI: newURI,
          IPFSData: null,
        });
      }

      if (creatorAddress === addressRef.current) {
        eventLoading.current &&
          navigate(
            replacePathsDynamicParts(paths.singleCollection, {
              replace: 'address',
              with: id,
            })
          );
        eventMessage(
          <Box>
            <EventMessageContent type="update-collection" address={creatorAddress} tokenId={id} />
          </Box>
        );

        setEventLoading(false);
      }
    },
    [allCollections, addressRef.current, appendCollection, navigate, setEventLoading, eventLoading.current]
  );

  const cancelRequestCertificate = useCallback(
    (certId: BigNumber, requesterAddress: string) => {
      const id = certId.toNumber()! + '';
      const data = allRequestCertificatesRef.current[id] ?? {};

      const isEmpty = isEmptyObj(data);
      if (!isEmpty) {
        appendRequestCertificate(id, { ...data!, isCanceled: true });
      }
      fetchNFT(data.NFTID, { disableCache: true });
      if (requesterAddress === addressRef.current) {
        eventMessage(
          <Box>
            <EventMessageContent type="cancel-request-certificate" address={requesterAddress} />
          </Box>
        );
        setEventLoading(false);
      }
    },
    [addressRef.current, allRequestCertificates, appendRequestCertificate, setEventLoading]
  );

  const collectionCreatedListener = useCallback(
    (collectionAddress: string, ownerAddress: string, id: BigNumber) => {
      console.log('event called, ', collectionAddress, ownerAddress);
      const collectionID = id.toNumber();
      setLastestCollectionsCount(collectionID);

      if (ownerAddress === addressRef.current) {
        setTimeout(() => {
          if (eventLoading.current) {
            console.log(createNFTValuesRef.current, !isEmptyObj(createNFTValuesRef.current));
            if (createNFTValuesRef.current && !isEmptyObj(createNFTValuesRef.current)) {
              navigate(paths.createNFT, {
                state: { createNFTValues: { ...createNFTValuesRef.current, collection: '' + collectionID } },
              });
            } else {
              navigate(
                replacePathsDynamicParts(paths.singleCollection, { replace: 'address', with: collectionID + '' })
              );
            }
          }
          setEventLoading(false);
          eventMessage(
            <Box>
              <EventMessageContent type="collection-created" tokenId={collectionAddress} address={ownerAddress} />
            </Box>
          );
        }, 5000);
      }
    },
    [
      setLastestCollectionsCount,
      addressRef.current,
      createNFTValuesRef.current,
      setEventLoading,
      navigate,
      eventLoading.current,
    ]
  );

    const watchContractEvent = ({ address, abi, eventName, listener }: any) => {
      const contract = new ethers.Contract(address, abi, provider);
      // const contract = new ethers.Contract(address, abi, new ethers.providers.WebSocketProvider('wss://polygon-testnet.blastapi.io/b74dc366-446c-4ffc-890f-ff6d2bb80e79'));
      contract.on(eventName, listener);
    };

  function initilizeEvents() {
    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'FixPrice',
      listener: fixPriceListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'itemListed',
      listener: itemListedListener as any,
    });

    watchContractEvent({
      address: network.NFTAddress as any,
      abi: network.NFTABI,
      eventName: 'tokenMinted',
      listener: tokenMintedListener as any,
    });

    watchContractEvent({
      address: network.NFTAddress as any,
      abi: network.NFTABI,
      eventName: 'collectionCreated',
      listener: collectionCreatedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'OfferAdded',
      listener: offerAddedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'auctionCancelled',
      listener: auctionCancelledListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'bidPlaced',
      listener: bidPlacedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'auctionSetted',
      listener: auctionSettedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'OfferWithdraw',
      listener: offerWithdrawListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'UpdatePrice',
      listener: updatePriceListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'auctionFinished',
      listener: auctionFinishedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'buyOuted',
      listener: buyOutedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'OfferAccepted',
      listener: offerAcceptedListener as any,
    });

    watchContractEvent({
      address: network.marketAddress as any,
      abi: network.marketABI,
      eventName: 'BuyNft',
      listener: buyNFTListener as any,
    });

    watchContractEvent({
      address: network.NFTAddress as any,
      abi: network.NFTABI,
      eventName: 'tokenURIUpdated',
      listener: updateNFTListener as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'SubmitRequestCertificate',
      listener: submitRequestCertificateListener as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'GenerateCertificateSubmited',
      listener: GenerateCertificateSubmittedListener as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'AcceptCertificateRequestbyValidator',
      listener: acceptCertificateListener as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'RequestReciveGemStoneByOwnerNFT',
      listener: requestReciveGemStoneListener as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'GemCertificateRequestWithdrawn',
      listener: cancelRequestCertificate as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'RequestToReceiveGemStoneWasCancelled',
      listener: cancelRequestToReceiveGemStone,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'ValidatorGiveGemtoOwnerSubmited',
      listener: validatorGiveGemtoOwnerSubmitedListener as any,
    });

    watchContractEvent({
      address: network.DAOAddress as any,
      abi: network.DAOABI,
      eventName: 'OwnerReceivedGemstoneFromValidator',
      listener: ownerReceivedGemstoneFromValidatorListener as any,
    });

    watchContractEvent({
      address: network.NFTAddress as any,
      abi: network.NFTABI,
      eventName: 'collectionURIUpdated',
      listener: collectionURIUpdatedListener as any,
    });
  }
};

export default useEvent;
