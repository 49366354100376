import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { MessageTypes } from 'global/types';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { messagesCount } from 'constants/defaults';
import { Transition } from 'react-transition-group';
import { TransitionStyles } from 'global/types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { mainBorderRadius } from 'themes';

interface MessageOptionsType {
  duration?: number;
}

type MessageType = (
  title?: string | React.ReactNode | undefined,
  content?: string | React.ReactNode,
  type?: MessageTypes,
  options?: MessageOptionsType
) => void;

const transitionDuration = 350;

const transitionStyles: TransitionStyles = {
  entering: { transform: 'translateX(-350px)' },
  entered: { transform: 'translateX(0)' },
  exiting: { transform: 'translateX(-350px)' },
};

const Message: React.FC<
  React.PropsWithChildren<{
    title?: string | React.ReactNode;
    type?: MessageTypes;
    root?: any;
    options?: MessageOptionsType;
  }>
> = ({ children, title, type, root, options }) => {
  const [openTransition, setOpenTransition] = React.useState<boolean>(false);

  const remove = React.useCallback((): void => {
    setOpenTransition(false);
    setTimeout(() => {
      root.unmount();
    }, transitionDuration);
  }, [root]);

  React.useEffect(() => {
    setOpenTransition(true);
    setTimeout(() => {
      remove();
    }, options?.duration ?? 10 * 1000);
  }, [remove, options?.duration]);

  return (
    <Transition
      in={openTransition}
      timeout={{
        enter: 10,
        exit: transitionDuration,
        appear: transitionDuration,
      }}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <Box
          id="message-wrapper"
          style={{
            transitionDuration: `${transitionDuration}ms`,
            transitionProperty: 'all',
            transform: 'translateX(-350px)',
            ...transitionStyles[state],
          }}
          className={`message-wrapper`}
          sx={{ backgroundColor: `${type}.main`, borderRadius: `${mainBorderRadius}px` }}
          mx={3}
          mt={1}
        >
          <Box py={1} px={2}>
            <Box mb={1}>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <Grid item>
                  <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => {
                      remove();
                    }}
                  >
                    <Icon style={{ color: '#fff' }} path={mdiClose} size={0.5} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            {children}
          </Box>
        </Box>
      )}
    </Transition>
  );
};

const message: MessageType = (title, content, type = 'primary', options = {}) => {
  for (let i = 1; i <= messagesCount; i++) {
    if (!Boolean(document.querySelector(`#messages-content #message-${i}`)?.innerHTML)) {
      const root = ReactDOM.createRoot(document.querySelector(`#messages-content #message-${i}`)!);
      root.render(
        <React.StrictMode>
          <Message title={title} root={root} type={type} options={options}>
            {content}
          </Message>
        </React.StrictMode>
      );
      break;
    } else {
      continue;
    }
  }
};

export default message;
