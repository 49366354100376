import create from 'zustand';

interface State {
  currencies: Record<string, string>;
  currenciesFetchingError: boolean;
  setCurrencies: (vals: Record<string, string>) => void;
  setCurrenciesFetchingError: (val: boolean) => void;
}

const useCurrency = create<State>()(set => ({
  currencies: {},
  setCurrencies: vals => {
    set({ currencies: vals });
  },
  currenciesFetchingError: false,
  setCurrenciesFetchingError: val => {
    set({ currenciesFetchingError: val });
  },
}));

export default useCurrency;
