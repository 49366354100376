import React from 'react';
import { FormattedMessage } from 'react-intl';
import Telegram from '@mui/icons-material/Telegram';
import EmailRounded from '@mui/icons-material/EmailRounded';
import { DiscordIcon } from 'assets/icons';

export const socialMedia = [
  {
    id: 'telegram',
    title: React.createElement(FormattedMessage, { id: 'social.telegram' }),
    link: 'https://t.me/Luxrare_io',
    icon: Telegram,
    iconProps: {},
  },
  {
    id: 'discord',
    title: React.createElement(FormattedMessage, { id: 'social.discord' }),
    link: 'https://discord.gg/FCmB5abuTE',
    icon: DiscordIcon,
    iconProps: { colorSetter: (theme: any) => theme.palette.primary.contrastText },
  },
  {
    id: 'email',
    title: React.createElement(FormattedMessage, { id: 'social.email' }),
    link: 'mailto:info@luxrare.io',
    icon: EmailRounded,
    iconProps: {},
  },
];

export const FooterHelpLinks = [
  {
    link: '#',
    title: React.createElement(FormattedMessage, { id: 'footer.help.link1' }),
  },
  {
    link: '#',
    title: React.createElement(FormattedMessage, { id: 'footer.help.link2' }),
  },
  {
    link: '#',
    title: React.createElement(FormattedMessage, { id: 'footer.help.link3' }),
  },
  {
    link: '#',
    title: React.createElement(FormattedMessage, { id: 'footer.help.link4' }),
  },
  {
    link: '#',
    title: React.createElement(FormattedMessage, { id: 'footer.help.link5' }),
  },
  {
    link: '#',
    title: React.createElement(FormattedMessage, { id: 'footer.help.link6' }),
  },
];
