// import * as React from 'react';
import ConfigProvider from 'config/ConfigProvider';
import ApplicationRouter from 'router';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useTheme } from 'themes';
import Loading from 'components/Loading';
import { BrowserRouter as Router } from 'react-router-dom';
// import { messagesCount } from 'constants/defaults';
import SwiperCore, { EffectFade, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Lazy } from 'swiper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FontConfig from 'components/FontConfig';
import ErrorBoundary from 'layouts/error-boundary';
import NotificationMessages from 'components/NotificationMessages';
import WagmiContainer from 'components/WagmiContainer';
import ConnectModal from 'components/ConnectModal';

SwiperCore.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Thumbs, Lazy]);

// TODO: multichains support
const App = () => {
  const theme = useTheme();

  return (
    <WagmiContainer>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <FontConfig />
          <Router>
            <ConfigProvider>
              <ErrorBoundary>
                <Loading />
                <ApplicationRouter />
                <NotificationMessages />
                <ConnectModal/>
              </ErrorBoundary>
            </ConfigProvider>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </WagmiContainer>
  );
};

export default App;
