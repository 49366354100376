import { proposalTypes, tickTypes } from 'constants/proposal';
import { PermissionItem, DaoProposals, NFTStatus } from 'global/enums';
import {
  BidHistoryObj,
  CertificatesListObj,
  CourtObj,
  FormattedCollectionData,
  OfferListObj,
  PriceHistoryObj,
  ProposalObj,
  RequestCertificateObj,
  UserPermission,
  ValidatorObj
} from 'global/interfaces';
import { FormattedNFTData } from 'global/types';
import { useCallback } from 'react';
import useData from 'store/data';
import bidHistoryMap from 'utils/bid-history-map';
import certificateListMap from 'utils/certificate-list-map';
import courtDataMap from 'utils/court-data-map';
import { formatAuctionData, formatCollectionContractData, formatNFTContractData } from 'utils/format-contract-data';
import historyMap from 'utils/history-map';
import offerListMap from 'utils/offer-list-map';
import proposalDataMap from 'utils/proposal-data-map';
import readItemsFromIPFS from 'utils/read-items-from-IPFS';
import requestCertificateDataMap from 'utils/request-certificate-data-map';
import validatorDataMap from 'utils/validator-data-map';
import useAppContract from './useAppContract';
import { weiToETH } from 'utils/price';
// import { ethers } from 'ethers';
// import { mdiConsoleNetwork } from '@mdi/js';

const useFetch = () => {
  const { readNFTContract, readMarketContract, readDAOContract } = useAppContract();

  const setCourteLoading = useData(state => state.setCourteLoading);
  const setAllNFTsCount = useData(state => state.setAllNFTsCount);
  const setFetchAllNFTLoading = useData(state => state.setFetchAllNFTLoading);
  const setLastestCollectionsCount = useData(state => state.setLastestCollectionsCount);
  const setCommonCollectionsCount = useData(state => state.setCommonCollectionsCount);
  const appendCollection = useData(state => state.appendCollection);
  const setFetchAllCollectionsLoading = useData(state => state.setFetchAllCollectionsLoading);
  const setSmartContractOwner = useData(state => state.setSmartContractOwner);
  const fetchAllNFTLoading = useData(state => state.fetchAllNFTLoading);
  const lastestCollectionsCount = useData(state => state.lastestCollectionsCount);
  const commonCollectionsCount = useData(state => state.commonCollectionsCount);
  const fetchAllValidatorsLoading = useData(state => state.fetchAllValidatorsLoading);
  const fetchAllCollectionsLoading = useData(state => state.fetchAllCollectionsLoading);
  const lastestNFTsCount = useData(state => state.lastestNFTsCount);
  const commonNFTsCount = useData(state => state.commonNFTsCount);
  const lastestCourtCount = useData(state => state.lastestCourtCount);
  const setAllCourtCount = useData(state => state.setAllCourtCount);
  const setDaoAndValidatorBalance = useData(state => state.setDaoAndValidatorBalance);
  const fetchAllCourtLoading = useData(state => state.fetchAllCourtLoading);
  const setFetchAllCourtLoading = useData(state => state.setFetchAllCourtLoading);
  const appendBulkCollections = useData(state => state.appendBulkCollections);
  const setRequestCertificateLoading = useData(state => state.setRequestCertificateLoading);
  const appendBulkRequestCertificates = useData(state => state.appendBulkRequestCertificates);
  const setMinimumWaitingTimeForApplicationWithdrawal = useData(
    state => state.setMinimumWaitingTimeForApplicationWithdrawal
  );
  const readNFT = useData(state => state.readNFT);
  const readCollection = useData(state => state.readCollection);
  const readRequestCertificate = useData(state => state.readRequestCertificate);
  const appendCourt = useData(state => state.appendCourt);
  const setFetchAllValidatorsLoading = useData(state => state.setFetchAllValidatorsLoading);
  const allCourt = useData(state => state.allCourt);
  const appendValidator = useData(state => state.appendValidator);
  const allValidator = useData(state => state.allValidator);
  const appendNFT = useData(state => state.appendNFT);
  const setAllProposalsCount = useData(state => state.setAllProposalsCount);
  const appendBulkProposals = useData(state => state.appendBulkProposals);
  const appendBulkNFTs = useData(state => state.appendBulkNFTs);
  const appendBulkCourt = useData(state => state.appendBulkCourt);
  const addBannedNFT = useData(state => state.addBannedNFT);
  const validatorDuration = useData(state => state.validatorDuration);
  const courteDuration = useData(state => state.courteDuration);
  const changeParameterDuration = useData(state => state.changeParameterDuration);
  const setValidatorDuration = useData(state => state.setValidatorDuration);
  const setCourteDuration = useData(state => state.setCourteDuration);
  const setChangeParameterDuration = useData(state => state.setChangeParameterDuration);
  const setAllRequestCertificateCount = useData(state => state.setAllRequestCertificateCount);
  const appendRequestCertificate = useData(state => state.appendRequestCertificate);

  const fetchNFT = (
    id: string,
    options?: { doNotAppend?: boolean; reportError?: boolean; disableCache?: boolean }
  ): Promise<FormattedNFTData | undefined> =>
    new Promise(async (resolve, reject) => {
      const { data: cachedData } = readNFT(id);
      if (!options?.disableCache && cachedData) {
        if (!cachedData.IPFSData) {
          const IPFSData = await readItemsFromIPFS(cachedData.tokenURI);
          appendNFT(id, 'data', { ...cachedData, IPFSData });
          return { ...cachedData, IPFSData };
        }
        resolve(cachedData);
        return cachedData;
      }
      const currentItem = await readMarketContract('marketItemsMap', +id);
      const contractData = formatNFTContractData(currentItem);

      !contractData?.category && options?.reportError && reject({ cantFind: true });
      if (!contractData.id) reject({ cantFind: true });
      if (!!contractData?.category) {
        const auctionData = formatAuctionData(await readMarketContract('AuctionsMapp', +id));
        const tokenURI = await readNFTContract('tokenURISs', contractData.id);

        // const tokenURI = await getERC721Contract(contractData.NFTContractAddress).tokenURI(contractData.id);


        !tokenURI && options?.reportError && reject({ cantFind: true });

        const collectionID = await readNFTContract('addressToId', contractData.NFTContractAddress);
        const IPFSData = await readItemsFromIPFS(tokenURI);



        const NFTData = !!IPFSData && {
          ...contractData,
          IPFSData,
          tokenURI,
          auctionData,
          collectionID: +collectionID.toString()
        };

        if (NFTData.status === NFTStatus.BANNED) {
          addBannedNFT(+id);
        }

        if (!options?.doNotAppend) {
          !!IPFSData && appendNFT(id + '', 'data', NFTData);
        }

        resolve(NFTData);
        return NFTData;
      } else {
        return undefined;
      }
    });

  const fetchCollection = (
    id: string,
    options?: {
      doNotAppend?: boolean;
      disableCache?: boolean;
      reportError?: boolean;
    }
  ): Promise<FormattedCollectionData | undefined> =>
    new Promise(async (resolve, reject) => {
      const cachedData = readCollection(id + '');
      if (cachedData && !options?.disableCache) {
        if (!cachedData.IPFSData) {
          const IPFSData = await readItemsFromIPFS(cachedData.collectionURI);
          appendCollection('' + id, { ...cachedData, IPFSData });
          return { ...cachedData, IPFSData };
        }
        resolve(cachedData);
        return cachedData;
      }

      const currentItem = await readNFTContract('fetchCollectionById', id);

      const contractData = formatCollectionContractData(id!, currentItem);

      !contractData?.collectionName && options?.reportError && reject({ cantFind: true });

      if (!!contractData?.collectionName) {
        const IPFSData = await readItemsFromIPFS(contractData.collectionURI);

        const collectionData = !!IPFSData && { ...contractData, IPFSData };

        if (!options?.doNotAppend && id) {
          IPFSData && appendCollection(id, collectionData);
        }

        resolve(collectionData);
        return collectionData;
      } else {
        reject({ message: 'Internal error' });
        return undefined;
      }
    });

  const fetchAllNFT = async (consideredNFTCount?: number): Promise<FormattedNFTData[] | undefined> => {
    if (!fetchAllNFTLoading) {

      setFetchAllNFTLoading(true);

      const itemPromises: any[] = [];
      const itemIds = await readMarketContract('_itemIds');
      const itemIdsCount =
        !lastestNFTsCount || lastestNFTsCount !== commonNFTsCount ? Number(itemIds.toString()) : lastestNFTsCount;

      setAllNFTsCount(itemIdsCount);

      for (
        let i = itemIdsCount;
        i >= (consideredNFTCount && itemIdsCount > consideredNFTCount ? itemIdsCount - consideredNFTCount + 1 : 1);
        i--
      ) {
        itemPromises.push(fetchNFT(i + '', { doNotAppend: true }));
      }

      const allNFTs: FormattedNFTData[] = await Promise.all(itemPromises);

      appendBulkNFTs(allNFTs.filter(item => item.IPFSData));

      setFetchAllNFTLoading(false);

      return allNFTs;
    } else {
      return undefined;
    }
  };

  const fetchAllCollections = async (): Promise<FormattedCollectionData[] | undefined> => {
    if (!fetchAllCollectionsLoading) {
      setFetchAllCollectionsLoading(true);
      const itemPromises: any[] = [];

      const collectionlength = await readNFTContract('collectionlength');
      const allCollectionsCount =
        !lastestCollectionsCount || lastestCollectionsCount !== commonCollectionsCount
          ? Number(collectionlength.toString())
          : lastestCollectionsCount;

      setLastestCollectionsCount(allCollectionsCount);
      setCommonCollectionsCount(allCollectionsCount);

      for (let i = 0; i <= allCollectionsCount - 1; i++) {
        itemPromises.push(fetchCollection(i + '', { doNotAppend: true }));
      }

      const allContractCollections = await Promise.all(itemPromises);

      appendBulkCollections(allContractCollections);

      setFetchAllCollectionsLoading(false);

      return allContractCollections;
    } else {
      return undefined;
    }
  };
  const fetchCollectionNFTs = async (id: string): Promise<FormattedNFTData[] | undefined> => {
    if (!fetchAllCollectionsLoading) {
      const collectionData = await fetchCollection(id);

      const itemPromises: any[] = [];

      collectionData?.tokenIds?.forEach(tokenId => itemPromises.push(fetchNFT(tokenId, { doNotAppend: false })));

      const collectionNFTs = await Promise.all(itemPromises);

      return collectionNFTs;
    } else {
      return undefined;
    }
  };

  const fetchCollectionOwnership = async (walletAddress: string) => {
    try {
      const userCollections = await readNFTContract('fetchUserCollections', walletAddress);
      userCollections?.collectionIds?.forEach((id: BigInt) => {
        fetchCollection('' + Number(id.toString()));
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllValidators = async (): Promise<ValidatorObj[] | undefined> => {
    if (!fetchAllValidatorsLoading && Object.keys(allValidator).length === 0) {
      setFetchAllValidatorsLoading(true);

      const itemPromises: any[] = [];
      const itemAddressesPromise: any[] = [];
      const validatorLength = await readDAOContract('validatorLength');
      const itemIdsCount = Number(validatorLength.toString());

      setAllNFTsCount(itemIdsCount);

      for (let i = 0; i < itemIdsCount; i++) {
        itemAddressesPromise.push(readDAOContract('Validators', i));
      }

      const allAddresses: string[] = await Promise.all(itemAddressesPromise);

      for (let i = 0; i < allAddresses.length; i++) {
        itemPromises.push(getValidatorData(allAddresses[i]));
      }

      const allValidators: ValidatorObj[] = await Promise.all(itemPromises);

      setFetchAllValidatorsLoading(false);

      return allValidators;
    } else {
      return undefined;
    }
  };

  const getUserPermission = async (walletAddress: string): Promise<UserPermission> => {
    const [isValidator, isCourt] = await Promise.all([
      readDAOContract('isValidatorMapping', walletAddress),
      readDAOContract('isCourtMapping', walletAddress)
    ]);

    return {
      isCourt: isCourt ? PermissionItem.HASPERMISSION : PermissionItem.HASNOTPERMISSION,
      isValidator: isValidator ? PermissionItem.HASPERMISSION : PermissionItem.HASNOTPERMISSION
    };
  };

  const getPriceHistory = async (id: string): Promise<PriceHistoryObj[]> => {
    const { priceHistory: cachedData } = readNFT(id);
    if (cachedData) {
      return cachedData;
    }
    const priceHistory = historyMap(await readMarketContract('fetchNFTHistory', +id));
    appendNFT(id, 'price', priceHistory);
    return priceHistory;
  };

  const getActiveOffer = async (
    id: string,
    address: string,
    options?: {
      doNotAppend: boolean;
    }
  ): Promise<Boolean> => {
    const isThereActiveOffer = await readMarketContract('UserOffers', +id, address);

    if (!options?.doNotAppend) appendNFT(id, 'activeOffer', isThereActiveOffer);

    return isThereActiveOffer;
  };

  const getOfferList = async (id: string): Promise<OfferListObj[] | null> => {
    const { offerList: cachedData } = readNFT(id);
    if (cachedData) {
      return cachedData;
    }
    const activeOffers: OfferListObj[] = [];
    const offers = await readMarketContract('fetchNFTOffers', +id);

    const offerList = offerListMap(offers)?.reverse();
    for (let key in offerList) {
      const isActive = await getActiveOffer(id, offerList[key].offerAddress, { doNotAppend: true });
      const isThere = activeOffers.find(offer => offer.offerAddress === offerList[key].offerAddress);
      if (isActive && !isThere) activeOffers.push(offerList[key]);
    }
    appendNFT(id, 'offer', activeOffers);
    return offerList;
  };

  const getCertificatesList = async (id: string, options?: { disableCache?: boolean, enableStatus?: boolean }): Promise<CertificatesListObj[] | null> => {
    const { certificates: cachedData } = readNFT(id);
    if (cachedData && !options?.disableCache) {
      return cachedData;
    }
    const [certificateList,
      certificateStatus
    ] = await Promise.all([
      readDAOContract('fetchAllCertificates', +id),
      options?.enableStatus ? readDAOContract('ItemIdsReciveGemStone', +id) : null
    ]);
    // console.log(certificateStatus)
    appendNFT(id, 'certificate', certificateListMap(certificateList));
    options?.enableStatus && appendNFT(id, 'certificate-status', certificateStatus);
    appendNFT(id, 'tick', certificateList.Tick);
    return certificateList;

    return [];
  };

  const getCanUpdate = async (
    id: FormattedNFTData['id'],
    collectionAddress: FormattedNFTData['NFTContractAddress']
  ): Promise<boolean> => {
    const canUpdate = await readMarketContract('canUpdateTokenURI', +id, collectionAddress);
    return !!canUpdate;
  };

  const getBidHistory = async (id: string): Promise<BidHistoryObj[] | null> => {
    const { bidHistory: cachedData } = readNFT(id);
    if (cachedData) {
      return cachedData;
    }
    const bidData = await readMarketContract('fetchBidHistory', +id);

    const bidHistory = bidHistoryMap(bidData);

    appendNFT(id, 'bid', bidHistory);
    return bidHistory;
  };

  const getProposalData = async (id: number): Promise<ProposalObj> => {
    const [proposal, isExecuted, isConfirmed] = await Promise.all([
      readDAOContract('ProposalMap', id),
      readDAOContract('IsProposalExecuted', id),
      readDAOContract('IsProposalConfirmed', id)
    ]);

    const mappedProposal = proposalDataMap(id + '', proposal);

    const proposalTypeObj = proposalTypes.find(p => p.id === mappedProposal.type);

    const [forAddress, tickType] =
      proposalTypeObj?.id === DaoProposals.AddOrDeleteCourts
        ? await readDAOContract('AddOrDeleteCourtsMap', id)
        : await readDAOContract('AddOrDeleteOrGoldenValidatorMap', id);

    const tickTypeObj = tickTypes.find(t => t.id === tickType);

    const description = await readItemsFromIPFS(mappedProposal.descriptionCID);

    return { ...mappedProposal, description, forAddress, tickTypeObj, proposalTypeObj, isExecuted, isConfirmed };
  };

  const getRequestCertificateData = async (
    id: number,
    options?: { doNotAppend?: boolean }
  ): Promise<RequestCertificateObj> => {
    const cachedData = readRequestCertificate('' + id);

    if (cachedData) {
      return cachedData;
    }

    const [requestCertificate] = await Promise.all([readDAOContract('CertificateRequestsMap', id)]);
    const mappedRequestCertificate = requestCertificateDataMap(id + '', requestCertificate);

    if (!options?.doNotAppend) {
      appendRequestCertificate('' + id, mappedRequestCertificate);
    }

    return mappedRequestCertificate;
  };

  const getAllProposalsPromises = async (fromID: number, toID: number): Promise<any> => {
    const itemPromises: any[] = [];

    for (let i = fromID; i <= toID; i++) {
      itemPromises.push(getProposalData(i));
    }

    const allProposals = await Promise.all(itemPromises);

    return allProposals;
  };

  const getDaoAndValidatorBalance = async (address: string): Promise<any> => {
    const balance = await readDAOContract('fetchAllMemberBalances', address);

    setDaoAndValidatorBalance(weiToETH(balance?.Balance));
    return null;
  };

  const getSmartContractOwner = async (): Promise<any> => {
    const address = await readNFTContract('Prohibitor');
    console.log(address)
    setSmartContractOwner(address);
  };

  const getAllProposals = async (): Promise<number | null> => {
    const [
      proposalNumbersInBigInt,
      validatorDurationInBigInt,
      courteDurationInBigInt,
      changeParameterDurationInBigInt
    ] = await Promise.all([
      readDAOContract('ProposalNumBers'),
      !validatorDuration && readDAOContract('DurationForValidatorProposal'),
      !courteDuration && readDAOContract('DurationForCourtProposal'),
      !changeParameterDuration && readDAOContract('DurationforChangingParameter')
    ]);

    setAllProposalsCount(+proposalNumbersInBigInt.toString());
    !!validatorDurationInBigInt && setValidatorDuration(+validatorDurationInBigInt.toString() * 1000);
    !!courteDurationInBigInt && setCourteDuration(+courteDurationInBigInt.toString() * 1000);
    !!changeParameterDurationInBigInt && setChangeParameterDuration(+changeParameterDurationInBigInt.toString() * 1000);


    const allProposals = await getAllProposalsPromises(1, +proposalNumbersInBigInt.toString());

    appendBulkProposals(allProposals);

    setCourteLoading(false);

    return +proposalNumbersInBigInt.toString();
  };

  const getAllRequestCertificatesPromises = async (fromID: number, toID: number): Promise<any> => {
    const itemPromises: any[] = [];

    for (let i = fromID; i <= toID; i++) {
      itemPromises.push(getRequestCertificateData(i, { doNotAppend: true }));
    }

    const allRequestCertificates = await Promise.all(itemPromises);

    return allRequestCertificates;
  };

  const getAllRequestCertificates = async (): Promise<number | null> => {
    const [requestCertificateNumbersInBigInt] = await Promise.all([readDAOContract('CertificateNumBers')]);
    setAllRequestCertificateCount(+requestCertificateNumbersInBigInt.toString());

    const allRequestCertificates = await getAllRequestCertificatesPromises(
      1,
      +requestCertificateNumbersInBigInt.toString()
    );
    appendBulkRequestCertificates(allRequestCertificates);

    setRequestCertificateLoading(false);

    return +requestCertificateNumbersInBigInt.toString();
  };

  const fetchMinimumWaitingTimeForApplicationWithdrawal = async () => {
    const MinimumWaitingTime = await readDAOContract('MinimumWaitingTimeForApplicationWithdrawal');
    if (+MinimumWaitingTime.toString()) setMinimumWaitingTimeForApplicationWithdrawal(+MinimumWaitingTime.toString());
  };

  const getValidatorData = useCallback(
    async (address: string, options?: { disableCache: boolean }) => {
      if (allValidator[address] && !options?.disableCache) {
        return allValidator[address];
      }

      const validator = validatorDataMap(await readDAOContract('fetchValidatorInformation', address), address);

      const IPFSData = await readItemsFromIPFS(validator.CID);

      appendValidator(address, { ...validator, IPFSData });

      return { ...validator, IPFSData };
    },
    [allValidator, appendValidator]
  );

  const getCourtData = useCallback(
    async (address: string, options?: { doNotAppend?: boolean }): Promise<CourtObj | null> => {
      if (allCourt[address]) {
        return allCourt[address];
      }
      const courts = await readDAOContract('CourtMapping', address);
      const court = courtDataMap(courts, address);
      const IPFSData = await readItemsFromIPFS(court.CID);

      !options?.doNotAppend && appendCourt(address, { ...court, IPFSData });

      return { ...court, IPFSData };
    },

    [allCourt, appendCourt]
  );

  const fetchCourt = async (id: string): Promise<null | CourtObj> => {
    const courtAddress = await readDAOContract('Courts', +id);
    const courtData = getCourtData(courtAddress, { doNotAppend: true });
    return courtData;
  };

  const fetchAllCourts = async (): Promise<FormattedNFTData[] | undefined> => {
    if (!fetchAllCourtLoading) {
      setFetchAllCourtLoading(true);

      const courtPromises: any[] = [];
      const courtLength = await readDAOContract('courtLength');
      const courtIdsCount = !lastestCourtCount ? +courtLength.toString() : lastestCourtCount;

      setAllCourtCount(courtIdsCount);

      for (let i = 0; i < courtIdsCount; i++) {
        courtPromises.push(fetchCourt(i + ''));
      }

      const allCourts = await Promise.all(courtPromises);

      appendBulkCourt(allCourts.filter(n => !!n));

      setFetchAllCourtLoading(false);

      // return allNFTs;
    } else {
      return undefined;
    }
  };
  // const use

  return {
    fetchNFT,
    getPriceHistory,
    getOfferList,
    getBidHistory,
    getAllProposals,
    getProposalData,
    getCertificatesList,
    getAllProposalsPromises,
    getCanUpdate,
    getCourtData,
    getValidatorData,
    getAllRequestCertificates,
    getActiveOffer,
    fetchCollectionNFTs,
    fetchCollection,
    fetchAllNFT,
    fetchAllCourts,
    getRequestCertificateData,
    getUserPermission,
    fetchAllValidators,
    fetchAllCollections,
    fetchCollectionOwnership,
    fetchMinimumWaitingTimeForApplicationWithdrawal,
    getDaoAndValidatorBalance,
    getSmartContractOwner
  };
};

export default useFetch;
