import { zeroPrice } from 'constants/defaults';
import { BigNumberish } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';

export const weiToETH = (price: BigNumberish): string => {
  return formatEther(price);
};

export const ETHToWei = (price: string): BigNumberish => {
  return parseEther(price);
};

export const isZeroPrice = (price: string): boolean => {
  return price === zeroPrice;
};

export const placeCommaBetween = (num: number): string => num.toLocaleString();
