import { ethers } from 'ethers';
import {
  useAccount,
  // usePublicClient,
  useWalletClient,
} from 'wagmi';
// import MarketABI from 'libs/polygon/MARKET_ABI.json';
// import DAOABI from 'libs/polygon/DAO_ABI.json';
import Erc20ABI from 'libs/common/ERC20_ABI.json';
import { polygonRPC } from 'constants/RPC';
import { contractConfig } from 'constants/chains';
import { readContract, writeContract } from 'wagmi/actions';
import React from 'react';
const {
  // REACT_APP_POLYGON_DAO_ADDRESS,
  // REACT_APP_POLYGON_MARKET_ADDRESS,
  REACT_APP_MAINNET_USDT_ADDRESS,
  REACT_APP_MAINNET_DAI_ADDRESS,
  REACT_APP_TESTNET_WETH_ADDRESS,
  REACT_APP_MAINNET_WETH_ADDRESS,
  REACT_APP_MAINNET_WBTC_ADDRESS,
} = process.env;

const RPCProvider = new ethers.providers.JsonRpcProvider(polygonRPC);

const useAppContract = () => {
  const { data: walletClient } = useWalletClient();
  // const publicClient = usePublicClient();
  const { address: userAddress } = useAccount();

  // TEMP SOLUTION
  const useContract = React.useCallback(
    ({ address, abi }: any) => {
      try {
        if (userAddress && walletClient) {
          const provider = new ethers.providers.Web3Provider(walletClient as any);
          const signer = provider.getSigner(userAddress);
          const contract = new ethers.Contract(address, abi, walletClient ? signer : RPCProvider);
          return contract;
        }
        const contract = new ethers.Contract(address, abi, RPCProvider);
        return contract;
      } catch (e) {
        // console.log(e);
      }
    },
    [userAddress, walletClient]
  );

  const readNFTContract = async (functionName: string, ...other: any) => {
    return (await readContract({
      functionName,
      abi: contractConfig.nftConfig.abi,
      address: contractConfig.nftConfig.address as any,
      args: [...other],
    })) as any;
  };

  const readMarketContract = async (functionName: string, ...other: any) => {
    return (await readContract({
      ...(contractConfig.marketConfig as any),
      functionName,
      args: [...other],
    })) as any;
  };

  const readDAOContract = async (functionName: string, ...other: any) => {
    return (await readContract({
      ...(contractConfig.daoConfig as any),
      functionName,
      args: [...other],
    })) as any;
  };

  const writeNFTContract = async (functionName: string, args: any, config?: any) => {
    return await writeContract({
      ...(contractConfig.nftConfig as any),
      functionName,
      args,
      ...config,
    });
  };
  const writeDAOContract = async (functionName: string, args: any, config?: any) => {
    return await writeContract({
      ...(contractConfig.daoConfig as any),
      functionName,
      args,
      ...config,
    });
  };
  const writeMarketContract = async (functionName: string, args: any, config?: any) => {
    return await writeContract({
      ...(contractConfig.marketConfig as any),
      functionName,
      args,
      ...config,
    });
  };

  // const signerContract = useContract({
  //   address: network.marketAddress,
  //   abi: network.marketABI,
  //   signerOrProvider: walletClient ?? RPCProvider,
  // });

  // const NFTContract = useContract({
  //   address: network.NFTAddress,
  //   abi: network.NFTABI,
  //   signerOrProvider: walletClient ?? RPCProvider,
  // });

  // const DAOSignerContract = useContract({
  //   address: network.DAOAddress,
  //   abi: network.DAOABI,
  //   signerOrProvider: walletClient ?? RPCProvider,
  // });

  // const DAOContract = useContract({
  //   address: network.DAOAddress,
  //   abi: network.DAOABI,
  //   signerOrProvider: publicClient,
  // });

  // const marketContract = useContract({
  //   address: REACT_APP_POLYGON_MARKET_ADDRESS,
  //   abi: MarketABI,
  //   signerOrProvider: RPCProvider,
  // });

  const erc20Contracts: Record<string, ReturnType<typeof useContract>> = {
    [REACT_APP_MAINNET_USDT_ADDRESS as string]: useContract({
      address: REACT_APP_MAINNET_USDT_ADDRESS,
      abi: Erc20ABI,
      signerOrProvider: walletClient ?? RPCProvider,
    }),
    [REACT_APP_MAINNET_DAI_ADDRESS as string]: useContract({
      address: REACT_APP_MAINNET_DAI_ADDRESS,
      abi: Erc20ABI,
      signerOrProvider: walletClient ?? RPCProvider,
    }),
    [REACT_APP_MAINNET_WETH_ADDRESS as string]: useContract({
      address: REACT_APP_MAINNET_WETH_ADDRESS,
      abi: Erc20ABI,
      signerOrProvider: walletClient ?? RPCProvider,
    }),
    [REACT_APP_MAINNET_WBTC_ADDRESS as string]: useContract({
      address: REACT_APP_MAINNET_WBTC_ADDRESS,
      abi: Erc20ABI,
      signerOrProvider: walletClient ?? RPCProvider,
    }),
    [REACT_APP_TESTNET_WETH_ADDRESS as string]: useContract({
      address: REACT_APP_TESTNET_WETH_ADDRESS,
      abi: Erc20ABI,
      signerOrProvider: walletClient ?? RPCProvider,
    }),
  };

  return {
    // signerContract,
    // NFTContract,
    // DAOContract,
    // DAOSignerContract,
    // marketContract,
    erc20Contracts,
    readNFTContract,
    readMarketContract,
    readDAOContract,
    writeNFTContract,
    writeDAOContract,
    writeMarketContract,
  };
};

export default useAppContract;
