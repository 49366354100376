import { LazySpinner } from 'components/Spinners';
import MainLayout from 'layouts/main';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import config from './config';
import paths from './paths';
import { ConnectStatus, RouterConfig } from './types';
import { useAccount } from 'wagmi';
import OpenWeb3Modal from 'components/OpenWeb3Modal';

const Page404 = React.lazy(() => import(/* webpackChunkName: "page-404" */ 'pages/errors/404'));

const Page500 = React.lazy(() => import(/* webpackChunkName: "page-500" */ 'pages/errors/500'));

const Guarded: React.FC<RouterConfig> = ({ middleware, ...restProps }) => {
  const { address } = useAccount();

  const { pathname } = useLocation();

  const [status, setStatus] = React.useState<ConnectStatus>(ConnectStatus.Loading);

  const getAndSetStatus = React.useCallback(() => {
    if (!!address) {
      setStatus(ConnectStatus.Authenticated);
    } else {
      setStatus(ConnectStatus.UnAuthenticated);
    }
  }, [address]);

  React.useEffect(() => {
    if (middleware?.type) getAndSetStatus();
  }, [getAndSetStatus, middleware]);

  React.useEffect(() => {
    getAndSetStatus();
  }, [getAndSetStatus]);

  React.useEffect(() => {
    pathname === paths.connect && setStatus(ConnectStatus.Loading);
  }, [pathname]);

  if (middleware?.type) {
    switch (status) {
      case ConnectStatus.Loading:
        return <LazySpinner />;
      case ConnectStatus.UnAuthenticated:
        if (middleware?.type === 'need-auth') {
          return (
            <OpenWeb3Modal>
              <Navigate to={paths.auth+`?return=${pathname}`} replace />
            </OpenWeb3Modal>
          );
          // return <Navigate to={middleware.redirectTo as string} replace />;
        } else {
          return <restProps.element />;
        }
      case ConnectStatus.Authenticated:
        if (middleware?.type === 'need-auth') {
          return <restProps.element />;
        } else {
          return <Navigate to={middleware.redirectTo as string} replace />;
        }
    }
  }
  return <restProps.element />;
};

const Router: React.FC = () => {
  const { pathname } = useLocation();

  const currentRoute = React.useMemo<RouterConfig | undefined>(() => config.find(c => c.path === pathname), [pathname]);

  const hasHeader = React.useMemo<boolean>(
    () => (typeof currentRoute?.hasHeader === 'boolean' ? currentRoute?.hasHeader : true),
    [currentRoute]
  );

  const hasFooter = React.useMemo<boolean>(
    () => (typeof currentRoute?.hasFooter === 'boolean' ? currentRoute?.hasFooter : true),
    [currentRoute]
  );

  const hasCustomHeader = React.useMemo<boolean>(
    () => (typeof currentRoute?.hasCustomHeader === 'boolean' ? currentRoute?.hasCustomHeader : false),
    [currentRoute]
  );
  return (
    
    <>
      <MainLayout hasHeader={hasHeader} hasFooter={hasFooter} hasCustomHeader={hasCustomHeader}>
        <React.Suspense fallback={<LazySpinner />}>
          <Routes>
            {config.map(
              c =>
                !(c.onlyOnDevelopment && process.env.NODE_ENV === 'production') && (
                  <Route key={c.id} path={c.path} element={<Guarded {...c} />} />
                )
            )}
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
          </Routes>
        </React.Suspense>
      </MainLayout>
    </>
  );
};

export default Router;
