import * as React from 'react';
import { useAccount } from 'wagmi';

const baseURL = '/images/profiles';

const imagesCounts = 9;

const imagesFormat = 'png';

const useProfileImage = (manualAddress?: string): { image: string } => {
  const { address } = useAccount();

  const lastCharAscciiCode = React.useMemo<number>(
    () =>
      manualAddress
        ? manualAddress.charCodeAt(manualAddress.length - 1)
        : !!address
        ? address.charCodeAt(address.length - 1)
        : 0,
    [address, manualAddress]
  );

  return { image: `${baseURL}/${(lastCharAscciiCode % imagesCounts) + 1}.${imagesFormat}` };
};

export default useProfileImage;
