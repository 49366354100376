import React from 'react';
import DashboardHeader from './DashboardHeader';
import Footer from './Footer';
import Header from './Header';
import Main from './Main';
import useEvent from '../../hooks/useEvent';
import ApproveDialog from 'components/ApproveDialog';

const MainLayout: React.FC<
  React.PropsWithChildren & { hasHeader: boolean; hasFooter: boolean; hasCustomHeader: boolean }
> = ({ children, hasHeader, hasFooter, hasCustomHeader }) => {
  const getHeader = () => {    
    if (hasCustomHeader) {
      return <DashboardHeader />;
    }
    if (hasHeader) {
      return <Header />;
    }

    return null;
  };
  useEvent();
  return (
    <>
      {getHeader()}
      <Main hasHeader={hasHeader}>{children}</Main>
      {hasFooter && <Footer />}
      <ApproveDialog/>
    </>
  );
};

export default MainLayout;
