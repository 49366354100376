import create from 'zustand';

interface State {
  loading: boolean;
  setLoading: (l: boolean) => void;
  eventLoading: boolean;
  setEventLoading: (l: boolean) => void;
}

const useLoading = create<State>()(set => ({
  loading: false,
  setLoading: l => set(() => ({ loading: l })),
  eventLoading: false,
  setEventLoading: l => set(() => ({ eventLoading: l })),
}));

export default useLoading;
