import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

interface LazySpinnerProps {
  description?: React.ReactNode;
}

const SpinnerCmp = styled(Box)(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const LazySpinner: React.FC<React.ComponentProps<typeof SpinnerCmp> & LazySpinnerProps> = ({
  description,
  ...props
}) => (
  <SpinnerCmp {...props}>
    <Box>
      <CircularProgress size={50} />
    </Box>
    {!!description && (
      <Box sx={{ textAlign: 'center' }} pt={1}>
        {description}
      </Box>
    )}
  </SpinnerCmp>
);
