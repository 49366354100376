const connectorsData = {
    socials: [
        {
            name: "Google",
            icon: "/images/connectors/google.svg"
        }
        ,
        {
            name: "Discord",
            icon: "/images/connectors/discord.svg"
        },
        {
            name: "Facebook",
            icon: "/images/connectors/fb.svg"
        }
    ],
    wallets: {
        injected: {
            icon: '/images/connectors/metamask.png',
            name: 'Metamask'
        },
        walletConnect: {
            icon: '/images/connectors/walletconnect.png',
            name: 'WalletConnect'
        },
        coinbaseWallet: {
            icon: '/images/connectors/coinbase.svg',
            name: 'Coinbase Wallet'
        }
    }

}

export default connectorsData