import { RequestCertificateObj } from 'global/interfaces';
import { weiToETH } from './price';

const requestCertificateDataMap = (id: string, requestCertificate: any): RequestCertificateObj => ({
  id: id,
  NFTID: Number(requestCertificate[0].toString()) + '',
  validatorFee: weiToETH(requestCertificate[1]),
  requester: requestCertificate[2],
  validator: requestCertificate[3],
  timeConfirmedByValidator: Number(requestCertificate[4].toString()) * 1000,
  isExecuted: requestCertificate[5],
  isCanceled: requestCertificate[6],
  addressOfMakeOfferRequester: requestCertificate[7],
  requestTime: Number(requestCertificate[8].toString()) * 1000,
  tokenAddress: requestCertificate[9],
  needGoldenTick: requestCertificate[10],
});

export default requestCertificateDataMap;
