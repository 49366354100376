import { Typography } from '@mui/material';
import { EventMessageContentType } from 'global/types';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { primaryColor } from 'themes';
import formatAddress from 'utils/format-address';

interface EventMessageContentProps {
  type: EventMessageContentType;
  address?: string;
  secondaryAddress?: string;
  tokenId?: string;
}

const EventMessageContent: React.FC<EventMessageContentProps> = ({ type, address, secondaryAddress, tokenId }) => {
  const text = React.useMemo<React.ReactNode>(() => {
    switch (type) {
      case 'update-NFT':
        return (
          <>
            <FormattedMessage id="events.update-NFT" />
          </>
        );
      case 'accept-certificate-by-validator':
        return (
          <>
            <FormattedMessage
              id="events.accept-certificate-by-validator"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'submit-request-certifcate':
        return (
          <>
            <FormattedMessage
              id="events.submit-request-certifcate"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'buy':
        return (
          <>
            <FormattedMessage
              id="events.buy"
              values={{
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'bid-success':
        return (
          <>
            <FormattedMessage
              id="events.bid-success"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'auction-cancel':
        return (
          <>
            <FormattedMessage
              id="events.auction-cancel"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'cancel-request-certificate':
        return (
          <>
            <FormattedMessage
              id="events.cancel-request-certificate"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'update-collection':
        return (
          <>
            <FormattedMessage
              id="events.update-collection"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'validator-give-gem':
        return (
          <>
            <FormattedMessage
              id="events.validator-give-gem"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'owner-give-gem':
        return (
          <>
            <FormattedMessage
              id="events.owner-give-gem"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'item-listed':
        return (
          <>
            <FormattedMessage
              id="events.item-listed"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'auction-finished':
        return (
          <>
            <FormattedMessage
              id="events.auction-finished"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'auction-started':
        return (
          <>
            <FormattedMessage
              id="events.auction-started"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'offer-cancel':
        return (
          <>
            <FormattedMessage
              id="events.offer-cancel"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'price-remove':
        return (
          <>
            <FormattedMessage
              id="events.price-remove"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'offer-added':
        return (
          <>
            <FormattedMessage
              id="events.offer-added"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'offer-withdraw':
        return (
          <>
            <FormattedMessage
              id="events.offer-withdraw"
              values={{
                tokenId,
              }}
            />
          </>
        );
      case 'buy-out':
        return (
          <>
            <FormattedMessage
              id="events.offer-withdraw"
              values={{
                tokenId,
              }}
            />
          </>
        );
      case 'offer-accepted':
        return (
          <>
            <FormattedMessage
              id="events.offer-accepted"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'collection-created':
        return (
          <>
            <FormattedMessage
              id="events.collection-created"
              values={{
                tokenId,
                address: formatAddress(address + ''),
              }}
            />
          </>
        );
      case 'update-price':
        return (
          <>
            <FormattedMessage
              id="events.update-price"
              values={{
                tokenId,
              }}
            />
   
          </>
        );
      case 'fix-price':
        return (
          <>
         <FormattedMessage
              id="events.fix-price"
              values={{
                tokenId,
              }}
            />
          </>
        );
      case 'change-certificate-status':
        return (
          <>
          <FormattedMessage
              id="events.change-certificate-status"
              values={{
                tokenId,
                secondaryAddress
              }}
            />
          </>
        );
      default:
        return <></>;
    }
  }, [type, tokenId, address, secondaryAddress]);
  
  return (
    <Typography component="h6" sx={{ fontWeight: 'bold', color: primaryColor }}>
      {text}
    </Typography>
  );

};

export default EventMessageContent;
