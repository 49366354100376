import * as React from 'react';
import { ReactComponent as LogoWhite } from 'assets/footer-logo.svg';
// import { ReactComponent as TextWhite } from 'assets/footer-text.svg';
import { styled } from '@mui/material/styles';
import { Box, ButtonBase, Divider, Grid, Hidden, Tooltip, Typography } from '@mui/material';
import Content from 'components/Content';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import paths from 'router/paths';
import { FooterHelpLinks, socialMedia } from 'constants/StaticData';

const MainFooter = styled(Box)(({ theme }) => ({
  backgroundColor: (theme as any).palette.primary.main,
  backgroundImage: 'url(/images/footer-bg.svg)',
  backgroundSize: '330px',
  backgroundPosition: '71% 20%',
  backgroundRepeat: 'no-repeat',
  color: (theme as any).palette.primary.contrastText,
  [theme.breakpoints.down('md')]: {
    backgroundImage: 'none',
  },
}));

const MainDivider = styled(Divider)(({ theme }) => ({
  background: (theme as any).palette.primary.contrastText,
  opacity: 0.5,
}));

const MainLayoutCustomLogo = styled(LogoWhite)(({ theme }) => {
  return {
    width: '3.2rem',
    height: '3.2rem',
    transform: 'translateY(3px)',
    [(theme as any).breakpoints.down('md')]: {
      width: '3rem',
      height: '3rem',
    },
  };
});

// const MainLayoutCustomText = styled(TextWhite)(({ theme }) => {
//   return {
//     width: '5.8rem',
//     height: '2.45rem',
//     transform: 'translateY(3px)',
//     [(theme as any).breakpoints.down('md')]: {
//       width: '4rem',
//       height: '1.25rem',
//     },
//   };
// });

const Footer: React.FC = () => {
  const SocialLinks = () => (
    <Box textAlign={{ md: 'left', xs: 'center' }} mt={{ md: 4, xs: 2 }}>
      <Typography component="div" fontWeight={'bold'} variant="h6">
        <FormattedMessage id="footer.join" />
      </Typography>
      <Grid container justifyContent={{ md: 'flex-start', xs: 'center' }} spacing={1}>
        {socialMedia.map((l, k) => (
          <Grid key={l.id} item>
            <a target="_blank" href={l.link} rel="noreferrer">
              <Tooltip placement="top" arrow title={l.title}>
                <ButtonBase sx={{ borderRadius: '15px', mt: 1, p: 1, background: 'rgba(255,255,255,0.2)' }}>
                  <l.icon sx={() => ({ width: 22, height: 22 })} {...l.iconProps} />
                </ButtonBase>
              </Tooltip>
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  return (
    <MainFooter component="footer" pt={{ md: 5, xs: 3 }} pb={2} id="application-footer">
      <Content>
        <Box mb={{ md: 3, xs: 2 }}>
          <Grid container spacing={{ md: 3, xs: 1 }}>
            <Grid item xs={12} lg={4}>
              <Box mb={{ md: 2, xs: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md="auto" sx={{ textAlign: 'center' }}>
                    <MainLayoutCustomLogo />
                  </Grid>
                  <Grid item xs={12} md="auto" sx={{ textAlign: 'center' }}>
                    {/* <MainLayoutCustomText /> */}
                  </Grid>
                </Grid>
              </Box>
              <Box mb={{ md: 2, xs: 1 }}>
                <Typography
                  component="div"
                  textAlign={'justify'}
                  sx={{
                    mx: { md: '0', xs: 'auto' },
                    maxWidth: { md: 250, xs: 305 },
                    textAlign: { md: 'justify', xs: 'center' },
                  }}
                  variant="body2"
                >
                  <FormattedMessage id="footer.description" />
                </Typography>
              </Box>
              <Hidden smDown>
                <SocialLinks />
              </Hidden>
            </Grid>
            <Grid textAlign={{ md: 'left', xs: 'center' }} item xs={12} lg={3}>
              <Box mb={{ md: 2, xs: 1 }}>
                <Typography component="div" variant="h5">
                  <FormattedMessage id="footer.company" />
                </Typography>
              </Box>
              <Box mb={{ md: 2, xs: 1 }}>
                <Link to={paths.aboutUs}>
                  <Typography component="span" variant="body2">
                    <FormattedMessage id="footer.about-us" />
                  </Typography>
                </Link>
              </Box>
              <Box mb={{ md: 2, xs: 1 }}>
                <a href="/whitepaper.pdf" target="_blank" rel="noreferrer">
                  <Typography component="span" variant="body2">
                    <FormattedMessage id="footer.whitepaper" />
                  </Typography>
                </a>
              </Box>
              <Box mb={{ md: 2, xs: 1 }}>
                <Link to={paths.terms}>
                  <Typography component="span" variant="body2">
                    <FormattedMessage id="footer.terms" />
                  </Typography>
                </Link>
              </Box>
              {/* <Box mb={2}>
                <Link to={{ pathname: paths.home }}>
                  <Typography component="span" variant="body1">
                    <FormattedMessage id="footer.road-map" />
                  </Typography>
                </Link>
              </Box> */}
              {/* <Box mb={2}>
                <a href="/pitch-deck.pdf" target="_blank" rel="noreferrer">
                  <Typography component="span" variant="body1">
                    <FormattedMessage id="footer.pitch-desk" />
                  </Typography>
                </a>
              </Box>
              <Box mb={2}>
                <Link to={{ pathname: paths.home }}>
                  <Typography component="span" variant="body1">
                    <FormattedMessage id="footer.github" />
                  </Typography>
                </Link>
              </Box> */}
            </Grid>
            <Grid textAlign={{ md: 'left', xs: 'center' }} item xs={12} lg={5}>
              <Box mb={2}>
                <Typography component="div" variant="h5">
                  <FormattedMessage id="footer.help" />
                </Typography>
              </Box>
              {FooterHelpLinks?.map((item, key) => (
                <Link key={key} to={item.link}>
                  <Box mb={{ md: 2, xs: 1 }}>
                    <Typography component="div" variant="body2">
                      {item.title}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Hidden smUp>
          <MainDivider />
        </Hidden>
        <Hidden smUp>
          <SocialLinks />
        </Hidden>
        <Box mt={3} sx={{ textAlign: 'center', opacity: 0.7 }}>
          <Typography component="span" variant="caption">
            @{new Date(Date.now()).getFullYear()} Dapper Box
          </Typography>
        </Box>
      </Content>
    </MainFooter>
  );
};

export default Footer;
