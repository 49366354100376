import React from 'react';
import styled from '@emotion/styled';
import { alpha, Box } from '@mui/material';

const MainMain = styled(Box)(({ theme }) => {
  return {
    minHeight: '100vh',
    maxWidth:'100vw',
    paddingTop: '77px',
    overflow:'hidden',
    backgroundColor: (theme as any).palette.grey[100],
    '&:before':{
      content:"''",
      zIndex:0,
      position:'absolute',
      left:-400,
      top:-50,
      width:700,
      height:600,
      background:alpha((theme as any).palette.primary.main,0.10),
      filter:'blur(300px)'
    },
    // '&:after':{
    //   content:"''",
    //   zIndex:0,
    //   position:'absolute',
    //   right:-400,
    //   top:'70%',
    //   width:500,
    //   height:500,
    //   background:alpha((theme as any).palette.secondary.main,0.10),
    //   filter:'blur(300px)'
    // },
    // backgroundImage: `radial-gradient(at 10px 200px, ${alpha(
    //   (theme as any).palette.primary.main,
    //   0.25
    // )} 0%, transparent 50%)`,
    
    '&.without-header': {
      paddingTop: 0,
    },
    '&>*': {
      position: 'relative',
      zIndex:2
    },
  };
});

const Main: React.FC<React.PropsWithChildren & { hasHeader: boolean }> = ({ children, hasHeader }) => {
  return (
    <MainMain component="main" className={hasHeader ? undefined : 'without-header'}>
      <div>
      {children}
      </div>
    </MainMain>
  );
};

export default Main;
