import { Box } from '@mui/material';
import * as React from 'react';

const Content: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Box>>> = ({ className, ...props }) => {
  const classes = React.useMemo<(string | undefined)[]>(() => {
    const classesArray = ['content-wrapper'];

    // Add external classes
    if (className) {
      classesArray.push(className);
    }

    return classesArray;
  }, [className]);

  return <Box px={2} {...props} className={classes.join(' ')} />;
};

export default Content;
