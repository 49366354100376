import React from 'react';

const LoadingDots: React.FC = () => {
  const [showDotsNum, setShowDotsNum] = React.useState<number>(0);

  React.useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    interval = setInterval(() => {
      setShowDotsNum(prevState => (prevState >= 3 ? 1 : prevState + 1));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {[1, 2, 3].map(num => (
        <span key={num} style={{ visibility: num <= showDotsNum ? 'visible' : 'hidden' }}>
          .{' '}
        </span>
      ))}
    </>
  );
};

export default LoadingDots;
