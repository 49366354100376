import { BigNumberish } from 'ethers';
import { OfferListObj } from 'global/interfaces';
import { weiToETH } from './price';

const offerListMap = (offers: any): OfferListObj[] =>
  offers.Price
    .map((p: BigNumberish, i: number) => ({
      offerAddress: offers['OfferAddresses'][i],
      price: weiToETH(p),
      tokenAddress: offers['TokenAddress'][i],
      validatorRequestAddress: offers['ValidatorRequest'][i],
      validatorFee: offers['ValidatorFee'][i],
      isAcceptedByOwner: Boolean(offers['IsAcceptedByOwner'][i]),
      // date: 1666235659692,
      // status: offers?.[6]?.[i],
    }))
    // .filter((p: OfferListObj) => p.status === OfferStatus.PENDING);

export default offerListMap;
