import * as React from 'react';
import Content from 'components/Content';
import { Box, Typography, Grid, Button, alpha } from '@mui/material';
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as WrongNetworkVector } from 'assets/wrong-network-vector.svg';
import { ReactComponent as WrongNetworkVectorPurple } from 'assets/wrong-network-vector-purple.svg';
import { useSwitchNetwork,useNetwork} from 'wagmi';
import parseDynamicTexts from 'utils/parse-dynamic-texts';
import { isMainNet } from 'constants/is-main-net';

const VectorWrapper = styled(Box)(({ theme }) => ({ width: '30%', top: 0, left: 16, position: 'absolute', zIndex: 0 }));

const PrimaryFadeButton = styled(Button)(({ theme }) => ({
  backgroundColor: alpha((theme as any).palette.primary.main, 0.2),
  '&:hover': {
    backgroundColor: alpha((theme as any).palette.primary.main, 0.12),
  },
}));

export const WrongNetwork: React.FC<{ purpleSVG?: boolean }> = ({ purpleSVG }) => {
  const { switchNetwork } = useSwitchNetwork();
  const { chains, chain } = useNetwork();
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!switchNetwork) {
      timeout = setTimeout(() => {
        window.location.reload();
      }, 1400);
      return () => clearTimeout(timeout);
    }
  }, [switchNetwork]);
  return (
    <>
      <Content my={{ xs: 3, md: 9 }} sx={{ maxWidth: 750, position: 'relative' }} component="section">
        <VectorWrapper>
          {purpleSVG ? (
            <WrongNetworkVectorPurple style={{ width: '100%', height: 'auto' }} />
          ) : (
            <WrongNetworkVector style={{ width: '100%', height: 'auto' }} />
          )}
        </VectorWrapper>
        <Box py={2} sx={{ zIndex: 1, position: 'relative' }}>
          <Box mb={3}>
            <Typography component="h1" sx={{ typography: { xs: 'h4', md: 'h1' }, textAlign: 'center' }}>
              <FormattedMessage id="wrong-network.wrong-network" />
            </Typography>
          </Box>
          <Box mb={3} px={{ xs: 1, md: 3 }}>
            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              {parseDynamicTexts(formatMessage({ id: 'wrong-network.description' }), [
                isMainNet ? formatMessage({ id: 'Mainnet' }) : formatMessage({ id: 'Mumbai' }),
              ])}
            </Typography>
          </Box>
          <Box px={{ xs: 4, md: 0 }}>
            <Grid spacing={1} container justifyContent="center">
              <Grid item xs={12} md={6}>
                <PrimaryFadeButton
                  color="primary"
                  fullWidth
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    switchNetwork?.(chains[0]?.id);
                  }}
                >
                  <FormattedMessage id="wrong-network.switch-network" />
                </PrimaryFadeButton>
              </Grid>
              {/* <Grid item xs={12} md={6}>
              <Button color="primary" fullWidth variant="outlined" size="large">
                <FormattedMessage id="wrong-network.i-need-help" />
              </Button>
            </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Content>
    </>
  );
};

const WrongNetworkFullPage: React.FC<React.ComponentProps<typeof WrongNetwork>> = props => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    py={4}
  >
    <WrongNetwork {...props} />
  </Box>
);

export default WrongNetworkFullPage;
