import { polygonMumbai, polygon } from 'wagmi/chains';
import { isMainNet } from './is-main-net';
import { polygonRPC } from './RPC';
console.log(polygon)
const validChains = [isMainNet ? {
    ...polygon, rpcUrls:
        { ...polygon.rpcUrls, default: { http: [polygonRPC] }, public: { http: [polygonRPC] } }
} : polygonMumbai];

export default validChains;
