export interface RouterConfig {
  id: string;
  path: string;
  element: React.LazyExoticComponent<React.FC<{}>>;
  middleware?: RouterMiddlewareActions;
  hasHeader?: boolean;
  hasFooter?: boolean;
  hasCustomHeader? : boolean
  onlyOnDevelopment? : boolean
}

export type RouterMiddlewareActionsType = 'need-auth' | 'need-unauth';

export interface RouterMiddlewareActions {
  type: RouterMiddlewareActionsType;
  redirectTo?: string;
}

export enum ConnectStatus {
  Loading = 0,
  UnAuthenticated = 1,
  Authenticated = 2,
}
