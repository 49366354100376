const paths = {
  home: '/' /** ✔ */,
  connect: '/connect' /** ✔ */,
  auth: '/auth' /** ✔ */,
  createNFT: '/create-nft' /** ✔ */,
  updateNFT: '/update-nft/:id' /** ✔ */,
  eachNFT: '/nft/:id' /** 🚧 */,
  showCertificate: '/show-certificate/:id' /** 🚧 */,
  sellNFT: '/nft/sell/:id' /** ✔ */,
  allNFTs: '/all-nfts' /** ✔ */,
  aboutUs: '/about-us' /** ✔ */,
  team: '/team' /** ✔ */,
  validatorProfile: '/validator-profile' /** ❌ */,
  validatorPublicProfile: '/validator-profile/:address' /** ❌ */,
  userProfile: '/user-profile' /** 🚧 */,
  validator: '/nft/validator/:id' /** 🚧 */,
  court: '/court' /** ❌ */,
  singleCollection: '/collection/:address' /** ✔ */,
  allCollections: '/all-collections' /** ✔ */,
  createCollection: '/create-collection' /** ✔ */,
  updateCollection: '/update-collection/:address' /** ✔ */,
  help: '/help' /** ✔ */,
  categories: '/categories' /** ✔ */,
  createCertificate: '/create-certificate/:id' /** 🚧 */,
  eachUser: '/profile/:address' /** ✔ */,
  terms: '/terms-and-conditions' /** ✔ */,
  generateCourtCID: '/generate-court-cid' /** ✔ */,
};

export default paths;
