export enum NFTContractState {
  PENDING,
  CONNECTED,
  NOTCONNECTED,
}

export enum PermissionItem {
  UNKNOWN,
  HASPERMISSION,
  HASNOTPERMISSION,
}

export enum AuctionStatus {
  STARTED = 0,
  ISGOINGON = 1,
  FINISHED = 2,
}

export enum NFTStatus {
  NOTACTIVE = 0,
  INPROGRESS = 1,
  FIXPRICE = 2,
  AUCTION = 3,
  BANNED = 4,
}

export enum DaoProposals {
  ChangingParameters,
  AddOrDeleteCourts,
  AddOrDeleteOrTickValidator,
}

export enum StatusOrPropesalRequest {
  ADD = 0,
  REMOVE = 1,
  UPTOGOLDEN = 2,
  DOWNTOSILVER = 3,
  BAN = 4,
  ACTIVE = 5,
}

export enum ValidDaoAddress {
  UNKNOWN,
  VALID,
  INVALID,
}

export enum SmartContractParams {
  CourteSplitFeePrcentage,
  NFTMarketFeePrecentegeForCertificateValidation,
  QuorumForChangingParameter,
  QuorumForAddOrDeleteCourt,
  QuorumForAddOrDeleteValidator,
  DurationForChangingParameter,
  DurationForAddOrDeleteCourte,
  DurationForAddOrDeleteValidator,
  WithdrawRequestTime,
}

export enum Ticks {
  Bronze,
  Silver,
  Gold,
}

export enum ApproveSteps {
  CONFIRM,
  FINISH,
}

export enum CertificateStatus {
  None,
  RequestReciveGemStone,
  TransferNFTFromValidator,
  ConfirmationReceivingNFTByOwner,
}

export enum VoteProposal {
  Null,
  Yes,
  No,
  Reluctant,
}
export enum OfferStatus {
  PENDING,
  CANCELED,
  ACCEPTED,
}
