import { Wallet } from 'global/interfaces';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MetaMaskButton, WalletConnectButton } from './Buttons';

export const metamask: (chainId: string) => Wallet = chainId => ({
  id: 'metamask',
  name: React.createElement(FormattedMessage, { id: 'connect.metamask' }),
  icon: '/images/metamask.png',
  button: <MetaMaskButton chainId={chainId} />,
});

export const walletConnect: Wallet = {
  id: 'wallet-connect',
  name: React.createElement(FormattedMessage, { id: 'connect.wallet-connect' }),
  icon: '/images/walletconnect.png',
  button: React.createElement(WalletConnectButton),
};
