import * as React from "react";
import { useSearchParams } from "react-router-dom";

const useQuery = () => {
  const [searchParams] = useSearchParams();

  return React.useMemo<Record<string, string>>(() => {
    const queries: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      queries[key] = value;
    });
    return queries;
  }, [searchParams]);
};

export default useQuery;
