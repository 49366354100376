import { Backdrop, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React from 'react';
import useLoading from 'store/loading';
import { ReactComponent as Logo } from 'assets/logo-gradient.svg';
import LoadingDots from './LoadingDots';
import { FormattedMessage } from 'react-intl';

const StyledLogo = styled(Logo)(({ theme }) => ({
  width: '90px',
  height: '90px',
  animation: 'rotate 2.8s linear infinite',
  marginBottom: 2,
}));

const LockTheUser: React.FC = () => {
  const eventLoading = useLoading(state => state.eventLoading);

  return (
    <>
      <Backdrop
        sx={{
          color: '#000',
          zIndex: 2200,
          backdropFilter: 'blur(10px)',
          textAlign: 'center',
          backgroundColor: 'rgba(255,255,255,0.3)',
        }}
        open={eventLoading}
        unmountOnExit
      >
        <div>
          <StyledLogo />
          <Typography fontWeight={'bold'}>
            <FormattedMessage id="please-wait" /> <LoadingDots />
          </Typography>
        </div>
      </Backdrop>
    </>
  );
};

export default LockTheUser;
