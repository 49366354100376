import { Button, styled } from '@mui/material';

const WalletButton = styled(Button)(({ theme }) => ({
  color: (theme as any).palette.text.primary,
  maxWidth: 500,
  width: '100%',
  '& img': {
    width: 50,
    height: 50,
    display: 'block',
    marginRight: 20,
  },
}));

export default WalletButton;
