import Decimal from 'decimal.js-light';
type OptionsType = {
  decimals?: number;
  thousandsSeprator?: boolean;
  round?: number;
  
};

const formatNumber = (value: number | string, options: OptionsType = {}) => {
  try {
    const { thousandsSeprator, decimals ,round } = options;
    let roundedNumber = new Decimal(value).toDecimalPlaces(decimals || 5,round||Decimal.ROUND_HALF_DOWN)?.toNumber();
    if (thousandsSeprator) roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return +roundedNumber;
  } catch (e) {
    // console.log('Decimal Error', e);
    return 0;
  }
};

export default formatNumber;
