import { FormattedContractData, FormattedAuctionData, FormattedCollectionData } from 'global/interfaces';
import { weiToETH } from './price';
import { BigNumber } from 'ethers';

export const formatNFTContractData = (contractData: any[]): FormattedContractData => {
  return {
    id: Number(contractData[0].toString()),
    contractOwnerAddress: contractData[1],
    creatorAddress: contractData[2],
    NFTContractAddress: contractData[3],
    tokenID: Number(contractData[4].toString()),
    royaltyFee: Number(contractData[5].toString()),
    price: weiToETH(contractData[6]),
    tokenERCAccepted: contractData[7],
    status: contractData[8],
    category: contractData[9],
    businessID: Number(contractData[10].toString()),
    registerDate:  Number(contractData[11].toString()) * 1000,
    lastTransferTime:  Number(contractData[12].toString()) * 1000,
  };
};

export const formatCollectionContractData = (id: string, contractData: any): FormattedCollectionData => {
  return {
    addressContract: contractData.addressContract,
    collectionName: contractData.collectionName,
    collectionSymbol: contractData.collectionSymbol,
    collectionURI: contractData.collectionURI,
    collectionOwner: contractData.owner,
    tokenIds: contractData.tokenIds.map((id: BigNumber) => Number(id.toString())),
    tokenURI: contractData.tokenIds,
    id,
  };
};

export const formatAuctionData = (auctionData: any[]): FormattedAuctionData => {
  return {
    endTime: Number(auctionData[0].toString()) * 1000,
    minPrice: weiToETH(auctionData[1]),
    buyNowPrice: weiToETH(auctionData[2]),
    NFTHighestBid: weiToETH(auctionData[3]),
    NFTHighestBidder: auctionData[4],
    NFTSeller: auctionData[5],
    bidIncreasePercentage: Number(auctionData[6].toString()),
    status: auctionData[7],
  };
};
