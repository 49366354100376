import { courtePageQuery, courteTabQuery, proposalTabQuery } from 'constants/defaults';
import tree, { voteTab } from 'containers/court/tree';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

const useCourteRouter = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const defaultPage = tree.find(t => t.default)!;

  const currentPage = (tree.find(t => t.id === query[courtePageQuery]) || defaultPage)!;

  const defaultTab = currentPage.tabs!.find(t => t.default)!;

  const currentTab = query[proposalTabQuery]
    ? voteTab
    : (currentPage.tabs!.find(t => t.id === query[courteTabQuery]) || defaultTab)!;

  const changePage = (id: string) => {
    const urlQueries = { ...query };
    urlQueries[courteTabQuery] = '';
    urlQueries[proposalTabQuery] = '';
    if (defaultPage?.id === id) {
      urlQueries[courtePageQuery] = '';
    } else {
      urlQueries[courtePageQuery] = id;
    }
    navigate(
      {
        pathname,
        /**
         * create search query string
         */
        search: `?${Object.keys({ ...urlQueries })
          .map(key => (!!urlQueries[key] ? `${key}=${urlQueries[key]}` : ''))
          .filter(q => !!q)
          .join('&')}`,
      },
      { replace: true }
    );
  };

  const changeTab = (id: string) => {
    const urlQueries = { ...query };
    urlQueries[proposalTabQuery] = '';
    if (defaultPage?.id === id) {
      urlQueries[courteTabQuery] = '';
    } else {
      urlQueries[courteTabQuery] = id;
    }
    navigate(
      {
        pathname,
        /**
         * create search query string
         */
        search: `?${Object.keys({ ...urlQueries })
          .map(key => (!!urlQueries[key] ? `${key}=${urlQueries[key]}` : undefined))
          .filter(q => !!q)
          .join('&')}`,
      },
      { replace: true }
    );
  };

  const openProposal = (id: string) => {
    const urlQueries = { ...query };
    urlQueries[proposalTabQuery] = id;
    navigate(
      {
        pathname,
        /**
         * create search query string
         */
        search: `?${Object.keys({ ...urlQueries })
          .map(key => (!!urlQueries[key] ? `${key}=${urlQueries[key]}` : undefined))
          .filter(q => !!q)
          .join('&')}`,
      },
      { replace: true }
    );
  };

  return { changePage, changeTab, defaultPage, currentPage, defaultTab, currentTab, openProposal };
};

export default useCourteRouter;
