import { messagesCount } from 'constants/defaults';
import React from 'react';

type Props = {};

const NotificationMessages = (props: Props) => {
  return (
    <div>
      <div id="messages-content">
        {Array.from({ length: messagesCount }).map((_, i) => (
          <div key={i + 1} id={`message-${i + 1}`} />
        ))}
      </div>
      <div id="event-messages-content">
        {Array.from({ length: messagesCount }).map((_, i) => (
          <div key={i + 1} id={`message-${i + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default NotificationMessages;
