import * as React from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { alpha, Box, Button, Divider, Grid, Drawer, IconButton, Typography, Avatar } from '@mui/material';
import { ReactComponent as Logo } from 'assets/logo-full.svg';
import WalletIcon from 'assets/icons/svgs/empty-wallet.svg';
import HambergerMenu from 'assets/icons/svgs/hamberger-menu.svg';
import useNavigation, { connectWallet } from './use-navigations';
import CloseIcon from '@mui/icons-material/Close';
import useProfileImage from 'hooks/useProfileImage';
import paths from 'router/paths';
import formatAddress from 'utils/format-address';
import tree from 'containers/court/tree';
import useCourteRouter from 'hooks/useCourteRouter';
import { FormattedMessage } from 'react-intl';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import { WalletIcon as MainWalletIcon } from 'assets/icons';
import MenuNavigation from './MenuNavigation';
import useBalance from 'hooks/useBalance';
import useData from 'store/data';

const MainHeader = styled(Box)(({ theme }) => {
  return {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha((theme as any).palette.background.paper, 0.8),
    backdropFilter: 'blur(20px)',
    zIndex: 1200,
    paddingBottom: (theme as any).spacing(1),
    paddingTop: (theme as any).spacing(1),
    paddingLeft: (theme as any).spacing(2),
    paddingRight: (theme as any).spacing(2),
    '@supports not (backdrop-filter: blur(20px))': {
      backgroundColor: (theme as any).palette.background.paper,
    },
    [(theme as any).breakpoints.down('md')]: {
      paddingBottom: (theme as any).spacing(0),
      paddingTop: (theme as any).spacing(0),
    },
  };
});

const AvatarContainer = styled(Box)(() => ({
  width: 50,
  height: 50,
  borderRadius: '100%',
  border: '3px solid #DADEE6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const MainLayoutCustomLogo = styled(Logo)(({ theme }) => {
  return {
    width: '8rem',
    transform: 'translateY(3px)',
    [(theme as any).breakpoints.down('md')]: {
      width: '6rem',
    },
  };
});

const NavbarDivider = styled(Divider)(({ theme }) => {
  return { marginLeft: (theme as any).spacing(2), marginRight: (theme as any).spacing(2) };
});

const NavWrapperComponent = styled(Grid)(({ theme }) => {
  return {
    [(theme as any).breakpoints.down('md')]: { display: 'none' },
  };
});

const HamburgarMenuWrapper = styled(Grid)(({ theme }) => {
  return { display: 'none', [(theme as any).breakpoints.down('md')]: { display: 'block' } };
});

const DashboardHeader: React.FC = () => {
  const { address } = useAccount();

  const { changePage, currentPage } = useCourteRouter();

  const { image } = useProfileImage();

  const [openMobileDrawer, setOpenMobileDrawer] = React.useState<boolean>(false);

  const { desktopNavigations } = useNavigation();

  const open = useData(state=>state.openConnector)

  const navigate = useNavigate();

  const { data: balance } = useBalance();

  return (
    <>
      <Drawer
        anchor="right"
        open={openMobileDrawer}
        onClose={() => {
          setOpenMobileDrawer(false);
        }}
      >
        <Box sx={{ width: 250 }} p={2}>
          <Box mb={2}>
            <Grid container>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setOpenMobileDrawer(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {address && (
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={'auto'}>
                    <AvatarContainer>
                      <Avatar src={image} sx={{ width: 50, height: 50 }} />
                    </AvatarContainer>
                  </Grid>
                  <Grid item container xs>
                    <Grid item xs={12}>
                      Luxrare
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color={'#1477F8'}>{formatAddress(address)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {tree.map(n => (
              <Box key={n.id}>
                <Button
                  fullWidth
                  color="inherit"
                  onClick={() => {
                    setOpenMobileDrawer(false);

                    if (n.navigate) {
                      navigate(n.navigate);
                    } else {
                      changePage(n.id);
                    }
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item className={`${currentPage?.id === n.id ? 'my-svg' : 'gray-svg'}`}>
                      <n.icon />
                    </Grid>
                    <Grid item>
                      <Typography
                        component="span"
                        variant="body1"
                        color={`${currentPage?.id === n.id ? '#833089' : '#707070'}`}
                      >
                        <FormattedMessage id={n.id} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
      <MainHeader component="header" id="application-header">
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <Box pr={{ xs: 2, md: 10 }}>
              <Link to={{ pathname: paths.home }} style={{ display: 'block' }}>
                <MainLayoutCustomLogo />
              </Link>
            </Box>
          </Grid>
          <HamburgarMenuWrapper item flexGrow={1}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item container xs={'auto'} alignItems={'center'} spacing={1}>
                <Grid item>
                  <Typography fontSize={12}>2223.021 USD</Typography>
                </Grid>
                <Grid item>
                  <img style={{ width: 20 }} src={WalletIcon} alt="WalletIcon" />
                </Grid>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setOpenMobileDrawer(true);
                  }}
                >
                  <img src={HambergerMenu} alt="HambergerMenu" />
                </IconButton>
              </Grid>
            </Grid>
          </HamburgarMenuWrapper>
          <NavWrapperComponent item flexGrow={1}>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box pr={3}>
                  <Box component="nav">
                    <Grid
                      container
                      component="ul"
                      alignItems="center"
                      sx={{ listStyle: 'none', margin: 0, padding: 0 }}
                    >
                      {desktopNavigations.map((n, i) => (
                        <React.Fragment key={n!.id}>
                          {i === 0 ? null : <NavbarDivider flexItem orientation="vertical" />}
                          <Grid item component="li">
                            {n!.newTab ? (
                              <a href={n!.to} target="_blank" rel="noreferrer">
                                {n!.title}
                              </a>
                            ) : (
                              <Link to={n!.to}>{n!.title}</Link>
                            )}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                {address ? (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <MenuNavigation />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {(+(balance?.formatted ?? 0)).toFixed(2)} {balance?.symbol}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <MainWalletIcon sx={() => ({ width: 30, height: 30, transform: 'translateY(3px)' })} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  // <Link to={connectWallet.to}>
                  <Button onClick={() => open()} color="primary" variant="outlined" endIcon={connectWallet.icon}>
                    {connectWallet.title}
                  </Button>
                )}
              </Grid>
            </Grid>
          </NavWrapperComponent>
        </Grid>
      </MainHeader>
    </>
  );
};

export default DashboardHeader;
