import React from 'react';
import { Helmet } from 'react-helmet';
type Props = {};

const FontConfig = (props: Props) => {
  return (
    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900"
      />
    </Helmet>
  );
};

export default FontConfig;
