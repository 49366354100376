import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { EmptyWalletTickIcon } from 'assets/icons';
import connectorsData from 'constants/connectorsData';
import useQuery from 'hooks/useQuery';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import paths from 'router/paths';
import useData from 'store/data';
import { Connector, useAccount, useConnect } from 'wagmi';
interface LinkBoxProps {
  disabled?: boolean;
}

const SocialBox = styled('a')<LinkBoxProps>(({ disabled }) => ({
  background: '#f7f7f8',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  flexDirection: 'column',
  padding: '15px 15px',
  minHeight: 100,
  transition: 'all 0.3s',
  filter: disabled ? 'grayscale(100%)' : '',
  opacity: disabled ? 0.8 : 1,
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': {
    background: !disabled ? '#ebeced' : '',
  },
}));

const WalletBox = styled('a')<LinkBoxProps>(({ disabled }) => ({
  background: '#f7f7f8',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  width: '100%',
  padding: '10px 15px',
  transition: 'all 0.3s',
  minHeight: '60px',
  filter: disabled ? 'grayscale(100%)' : '',
  opacity: disabled ? 0.8 : 1,
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': {
    background: !disabled ? '#ebeced' : '',
  },
}));

const ConnectModal = () => {
  const isConnectorOpen = useData(state => state.isConnectorOpen);
  const close = useData(state => state.closeConnector);
  const { connectAsync, connectors } = useConnect();
  const { isConnecting } = useAccount({ onConnect: () => connectCallBack() });
  const { open } = useWeb3Modal();
  const query = useQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const closeConnector=(options?:{checkRoute:boolean})=>{
    close()
    if(options?.checkRoute && pathname===paths.auth){
      navigate(paths.home);
    }
  }
  const connectCallBack = () => {
    closeConnector();
    if (query['return']) {
      navigate(query['return']);
    }
  };
  
  const handleConnect = (connector: Connector<any, any>) => {
    connectAsync({
      connector,
    })
      .catch(e => console.log(e));
  };

  return (
    <Dialog
      fullScreen={isMobile}
      PaperProps={{ position: 'relative' }}
      fullWidth
      maxWidth={'sm'}
      open={isConnectorOpen}
      onClose={()=>closeConnector({checkRoute:true})}
    >
      {isConnecting && (
        <LinearProgress sx={{ position: 'absolute', width: '100%', top: 0, right: 0 }} variant="query" />
      )}
      <DialogTitle>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <Grid container alignItems={'center'}>
              <EmptyWalletTickIcon size={22} colorSetter={theme => theme.palette.primary.main} />
              <Typography variant="h6" ml={1}>
                Connect wallet
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={()=>closeConnector({checkRoute:true})}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          By connecting your wallet, you agree to the{' '}
          <Link to="/terms-and-conditions">
            <Typography
              onClick={()=>closeConnector()}
              component={'span'}
              variant="body2"
              color={'secondary'}
              sx={{ textDecoration: 'underline ', cursor: 'pointer' }}
            >
              Terms of service
            </Typography>
          </Link>{' '}
          and{' '}
          <Link to="/terms-and-conditions">
            <Typography
              onClick={()=>closeConnector()}
              component={'span'}
              variant="body2"
              color={'secondary'}
              sx={{ textDecoration: 'underline ', cursor: 'pointer' }}
            >
              Privacy Policy
            </Typography>
          </Link>
          .
        </Typography>
        <Grid spacing={2} mt={2} container>
          {connectorsData.socials.map((info, index) => (
            <Grid item xs={index === 0 ? 12 : 6} md={4}>
              <SocialBox
                //  disabled={isConnecting}
                onClick={() => handleConnect(connectors[index])}
              >
                <img width={40} height={40} src={info.icon} />
                <Typography fontWeight={400} mx={'auto'} variant="body1">
                  {info.name}
                </Typography>
              </SocialBox>
            </Grid>
          ))}
          <Grid xs={12} item>
            <Divider />
          </Grid>

          {connectors.slice(3, 6).map((connector, index) => {
            const info = (connectorsData as any).wallets?.[connector.id];
            return (
              <Grid md={6} xs={12} item>
                <WalletBox
                  // disabled={isConnecting}
                  onClick={() => (connector.id === 'walletConnect' ? open() : handleConnect(connector))}
                >
                  <img width={40} src={info?.icon} />
                  <Typography variant="body2" sx={{ fontSize: { md: 14, xs: 12 } }} fontWeight={500} mx="auto">
                    {info?.name}
                  </Typography>
                  <IconButton>
                    <ChevronRightRounded />
                  </IconButton>
                </WalletBox>
              </Grid>
            );
          })}
          <Grid md={6} xs={12} item>
            <WalletBox
              //  disabled={isConnecting}
              onClick={() => open({ view: 'Connect' })}
            >
              <Box
                sx={{
                  width: '40px',
                  background: 'rgba(235, 235, 237,0.8)',
                  height: '40px',
                  borderRadius: '5px',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6">•••</Typography>
              </Box>
              <Typography variant="body2" sx={{ fontSize: { md: 14, xs: 12 } }} fontWeight={500} mx="auto">
                More wallets
              </Typography>
              <IconButton>
                <ChevronRightRounded />
              </IconButton>
            </WalletBox>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectModal;