import {
  AllProposalIcon,
  ExitIcon,
  HelpIcon,
  TheiaListIcon,
  TitanListIcon,
  TheiaProposalIcon,
  ParameterProposalIcon,
} from 'assets/icons';
import { lazy } from 'react';
import { DaoProposals } from 'global/enums';
import paths from 'router/paths';

const Proposals = lazy(() => import(/* webpackChunkName: "proposals" */ './Proposals'));
const ProposalControl = lazy(() => import(/* webpackChunkName: "proposal-control" */ './ProposalControl'));
const ParameterProposalContent = lazy(() => import(/* webpackChunkName: "parameter-proposal" */ './ParameterProposal'));
const Vote = lazy(() => import(/* webpackChunkName: "vote" */ './Vote'));

export const voteTab = {
  id: 'vote',
  content: <Vote />,
};

const tree = [
  {
    id: 'theia-proposal',
    icon: (props: any) => <TheiaProposalIcon {...props} />,
    tabs: [
      {
        id: 'theia-proposals',
        default: true,
        content: <Proposals see="theia" />,
      },
      {
        id: 'add',
        content: <ParameterProposalContent type={DaoProposals.AddOrDeleteOrTickValidator} />,
      },
      {
        id: 'remove',
        content: <ProposalControl key="remove" type={DaoProposals.AddOrDeleteOrTickValidator} />,
      },
      {
        id: 'block-unblock-with-for-slash',
        content: <ProposalControl key="block-unblock" type={DaoProposals.AddOrDeleteOrTickValidator} blockUnblock />,
      },
      {
        id: 'tick',
        content: <ProposalControl key="block-unblock" type={DaoProposals.AddOrDeleteOrTickValidator} tick />,
      },
    ],
  },
  {
    id: 'all-proposals',
    default: true,
    icon: (props: any) => <AllProposalIcon {...props} />,
    tabs: [
      {
        id: 'all-proposals',
        default: true,
        content: <Proposals see="all" />,
      },
      {
        id: 'active',
        content: <Proposals see="active" />,
      },
      {
        id: 'closed',
        content: <Proposals see="closed" />,
      },
      {
        id: 'apply',
        content: <Proposals see="apply" />,
      },
    ],
  },
  {
    id: 'titan-proposal',
    icon: (props: any) => <TheiaProposalIcon {...props} />,
    tabs: [
      {
        id: 'titan-proposals',
        default: true,
        content: <Proposals see="titan" />,
      },
      {
        id: 'add',
        content: <ParameterProposalContent type={DaoProposals.AddOrDeleteCourts} />,
      },
      {
        id: 'remove',
        content: <ProposalControl key="remove" type={DaoProposals.AddOrDeleteCourts} />,
      },
      {
        id: 'block-unblock-with-for-slash',
        content: <ProposalControl key="block-unblock" type={DaoProposals.AddOrDeleteCourts} blockUnblock />,
      },
    ],
  },
  {
    id: 'parameter-proposal',
    icon: (props: any) => <ParameterProposalIcon {...props} />,
    tabs: [
      {
        id: 'parameter-proposals',
        default: true,
        content: <></>,
      },
    ],
    disabled:true
  },
  {
    id: 'titan-list',
    icon: (props: any) => <TitanListIcon {...props} />,
    tabs: [
      {
        id: 'test',
        default: true,
        content: <></>,
      },
    ],
    disabled:true
  },
  {
    id: 'theia-list',
    icon: (props: any) => <TheiaListIcon {...props} />,
    tabs: [
      {
        id: 'test',
        default: true,
        content: <></>,
      },
    ],
    disabled:true
  },
  {
    id: 'help',
    icon: (props: any) => <HelpIcon {...props} />,
    navigate: paths.help,
    disabled:true
  },
  {
    id: 'exit',
    navigate: paths.home,
    icon: (props: any) => <ExitIcon {...props} />,
  },
];

export default tree;
