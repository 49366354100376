import create from 'zustand';
import { FormattedNFTData } from 'global/types';
import {
  BidHistoryObj,
  CertificatesListObj,
  CourtObj,
  OfferListObj,
  PriceHistoryObj,
  ProposalObj,
  ValidatorObj,
  RequestCertificateObj,
  FormattedCollectionData,
} from 'global/interfaces';
import { ApproveSteps, CertificateStatus, Ticks } from 'global/enums';

type AppendTypes = 'data' | 'bid' | 'offer' | 'price' | 'certificate' | 'activeOffer' | 'tick' | 'certificate-status';
type DataDataType = FormattedNFTData | undefined;
type DataOfferType = OfferListObj[] | undefined;
type DataPriceType = PriceHistoryObj[] | undefined;
type DataBidType = BidHistoryObj[] | undefined;
type DataCertificateType = CertificatesListObj[] | undefined;
type DataTicksType = Ticks | undefined;
type DataCertificateStatusType = CertificateStatus | undefined;
type ApproveData = {
  action?: () => void;
  step: ApproveSteps;
  finalAction?: () => void
};

interface State {
  daoAndValidatorBalance: string | null;
  setDaoAndValidatorBalance: (balance: string) => void;
  smartContractOwner: string | null;
  setSmartContractOwner: (address: string) => void;
  courteLoading: boolean;
  bannedNFT: number[];
  addBannedNFT: (id: number) => void;
  setCourteLoading: (l: boolean) => void;
  fetchAllCourtLoading: boolean;
  setFetchAllCourtLoading: (l: boolean) => void;
  fetchAllNFTLoading: boolean;
  setFetchAllNFTLoading: (l: boolean) => void;
  fetchAllCollectionsLoading: boolean;
  setFetchAllCollectionsLoading: (l: boolean) => void;
  fetchAllValidatorsLoading: boolean;
  setFetchAllValidatorsLoading: (l: boolean) => void;
  openConnector: () => void;
  closeConnector: () => void;
  isConnectorOpen: boolean;
  isApproveDialogOpen: boolean;
  setIsApproveDialogOpen: (v: boolean) => void;
  approveData: ApproveData;
  setApproveData: (data: ApproveData) => void;
  clearAllNFTs: () => void;
  clearAllCollections: () => void;
  requestCertificateLoading: boolean;
  setRequestCertificateLoading: (l: boolean) => void;
  commonProposalsCount: number | null;
  commonNFTsCount: number | null;
  lastestNFTsCount: number | null;
  minimumWaitingTimeForApplicationWithdrawal: number | null;
  setMinimumWaitingTimeForApplicationWithdrawal: (minimumWaitingTime: number | null) => void;
  setLastestNFTsCount: (lastestNFTsCount: number) => void;
  setAllNFTsCount: (allNFTsCount: number) => void;
  lastestCourtCount: number | null;
  setAllCourtCount: (allNFTsCount: number) => void;
  setCommonProposalsCount: (commonProposalsCount: number) => void;
  latestProposalsCount: number | null;
  lastestCollectionsCount: number | null;
  commonCollectionsCount: number | null;
  allRequestCertificateCount: number | null;
  validatorDuration: number | null;
  courteDuration: number | null;
  changeParameterDuration: number | null;
  setLastestCollectionsCount: (all: number) => void;
  setCommonCollectionsCount: (all: number) => void;
  setAllRequestCertificateCount: (allRequestCertificateCount: number) => void;
  readRequestCertificate: (id: string) => State['allRequestCertificates'][string];
  setLatestProposalsCount: (latestProposalsCount: number) => void;
  setAllProposalsCount: (allProposalsCount: number) => void;
  setValidatorDuration: (validatorDuration: number) => void;
  setCourteDuration: (courteDuration: number) => void;
  setChangeParameterDuration: (changeParameterDuration: number) => void;
  allProposals: Record<string, ProposalObj>;
  allRequestCertificates: Record<string, RequestCertificateObj>;
  allCourt: Record<string, CourtObj>;
  appendCourt: (address: string, data: CourtObj) => void;
  appendRequestCertificate: (id: string, data: RequestCertificateObj) => void;
  allValidator: Record<string, ValidatorObj>;
  appendValidator: (address: string, data: ValidatorObj) => void;
  appendProposal: (id: string, data: ProposalObj) => void;
  appendBulkProposals: (proposals: ProposalObj[]) => void;
  appendBulkCollections: (proposals: FormattedCollectionData[]) => void;
  appendBulkRequestCertificates: (proposals: RequestCertificateObj[]) => void;
  appendBulkCourt: (proposals: CourtObj[]) => void;
  allCollections: Record<string, FormattedCollectionData>;
  myCollections: Record<string, FormattedCollectionData>;
  allNFTs: Record<
    string,
    {
      id: any;
      data: DataDataType;
      bidHistory: DataBidType;
      offerList: DataOfferType;
      activeOffer?: boolean;
      priceHistory: DataPriceType;
      certificateStatus: DataCertificateStatusType;
      tick: DataTicksType;
      certificates: DataCertificateType;
    }
  >;
  myNFTs: string[];
  appendNFT: <T extends AppendTypes>(
    id: string,
    type: T,
    data: T extends 'data'
      ? DataDataType
      : T extends 'offer'
      ? DataOfferType
      : T extends 'bid'
      ? DataBidType
      : T extends 'price'
      ? DataPriceType
      : T extends 'activeOffer'
      ? boolean
      : T extends 'certificate'
      ? DataCertificateType
      : T extends 'certificate-status'
      ? DataCertificateStatusType
      : undefined
  ) => void;
  appendBulkNFTs: (NFTs: FormattedNFTData[]) => void;
  appendCollection: (addressContract: string, collection: FormattedCollectionData) => void;
  appendToMyCollections: (id: string, collection: FormattedCollectionData) => void;
  readNFT: (id: string) => State['allNFTs'][string];
  readCollection: (id: string) => State['allCollections'][string];
}

const useData = create<State>()((set, get) => ({
  daoAndValidatorBalance: null,
  bannedNFT: [],
  addBannedNFT: id => {
    set({ bannedNFT: [...get().bannedNFT, id] });
  },
  courteLoading: true,
  smartContractOwner: null,
  setSmartContractOwner: address => {
    set({ smartContractOwner: address });
  },
  fetchAllCourtLoading: false,
  fetchAllNFTLoading: false,
  fetchAllCollectionsLoading: false,
  fetchAllValidatorsLoading: false,
  setCourteLoading: l => {
    set({ courteLoading: l });
  },
  setFetchAllCourtLoading: l => {
    set({ fetchAllCourtLoading: l });
  },
  setDaoAndValidatorBalance: balance => {
    set({ daoAndValidatorBalance: balance });
  },
  setFetchAllNFTLoading: l => {
    set({ fetchAllNFTLoading: l });
  },
  setFetchAllCollectionsLoading: l => {
    set({ fetchAllCollectionsLoading: l });
  },
  setFetchAllValidatorsLoading: l => {
    set({ fetchAllValidatorsLoading: l });
  },
  requestCertificateLoading: true,
  setRequestCertificateLoading: l => {
    set({ requestCertificateLoading: l });
  },
  commonProposalsCount: null,
  setCommonProposalsCount: commonProposalsCount => {
    set({ commonProposalsCount });
  },
  commonNFTsCount: null,
  lastestNFTsCount: null,
  lastestCourtCount: null,
  approveData: {
    step: ApproveSteps.CONFIRM,
    action: undefined,
    finalAction: undefined
  },
  setApproveData(data) {
    set({ approveData: data });
  },
  setAllNFTsCount: allNFTsCount => {
    set({ commonNFTsCount: allNFTsCount, lastestNFTsCount: allNFTsCount });
  },
  setLastestNFTsCount: lastestNFTsCount => {
    set({ lastestNFTsCount });
  },
  setAllCourtCount: lastestCourtCount => {
    set({ lastestCourtCount });
  },
  setLastestCollectionsCount: val => {
    set({
      lastestCollectionsCount: val,
    });
  },
  setCommonCollectionsCount: val => {
    set({
      commonCollectionsCount: val,
    });
  },
  isApproveDialogOpen: false,
  setIsApproveDialogOpen: val => {
    set({
      isApproveDialogOpen: val,
    });
  },
  latestProposalsCount: null,
  allRequestCertificateCount: null,
  validatorDuration: null,
  courteDuration: null,
  lastestCollectionsCount: null,
  commonCollectionsCount: null,
  changeParameterDuration: null,
  isConnectorOpen: false,
  openConnector: () => set({ isConnectorOpen: true }),
  closeConnector: () => set({ isConnectorOpen: false }),
  minimumWaitingTimeForApplicationWithdrawal: null,
  setMinimumWaitingTimeForApplicationWithdrawal: minimumWaitingTime => {
    set({
      minimumWaitingTimeForApplicationWithdrawal: minimumWaitingTime,
    });
  },
  setLatestProposalsCount: latestProposalsCount => {
    set({ latestProposalsCount });
  },
  setAllProposalsCount: allProposalsCount => {
    set({ commonProposalsCount: allProposalsCount, latestProposalsCount: allProposalsCount });
  },
  setAllRequestCertificateCount: allRequestCertificateCount => {
    set({ allRequestCertificateCount });
  },
  setCourteDuration: courteDuration => {
    set({ courteDuration });
  },
  setValidatorDuration: validatorDuration => {
    set({ validatorDuration });
  },
  setChangeParameterDuration: changeParameterDuration => {
    set({ changeParameterDuration });
  },
  allRequestCertificates: {},
  allProposals: {},
  allCourt: {},
  appendCourt: (address, court) => {
    set(({ allCourt }) => ({ allCourt: { ...allCourt, [address]: court } }));
  },
  appendRequestCertificate: (id, requestCertificate) => {
    set(({ allRequestCertificates }) => ({
      allRequestCertificates: { ...allRequestCertificates, [id]: requestCertificate },
    }));
  },
  allValidator: {},
  appendValidator: (address, validator) => {
    set(({ allValidator }) => ({ allValidator: { ...allValidator, [address]: validator } }));
  },
  appendProposal: (id, proposal) => {
    set(({ allProposals }) => ({ allProposals: { ...allProposals, [id]: proposal } }));
  },
  appendBulkProposals: proposals => {
    const appendedProposals: State['allProposals'] = {};
    proposals.forEach(p => {
      appendedProposals[p.id] = p;
    });
    set(({ allProposals }) => ({ allProposals: { ...allProposals, ...appendedProposals } }));
  },
  appendBulkCollections: proposals => {
    const appendedCollections: State['allCollections'] = {};
    proposals.forEach(p => {
      appendedCollections[p.id] = p;
    });
    set(({ allCollections }) => ({ allCollections: { ...allCollections, ...appendedCollections } }));
  },
  appendBulkNFTs: NFTs => {
    const appendedNFTs: State['allNFTs'] = {};
    NFTs.forEach(NFT => {
      appendedNFTs['' + NFT.id] = { ...(get().allNFTs[NFT.id] ?? {}), data: NFT };
    });
    set(({ allNFTs }) => ({ allNFTs: { ...allNFTs, ...appendedNFTs } }));
  },
  appendBulkCourt: courts => {
    const appendedCourts: State['allCourt'] = {};
    courts.forEach(court => {
      appendedCourts['' + court.address] = { ...(get().allCourt[court.address] ?? {}), ...court };
    });
    set(({ allCourt }) => ({ allCourt: { ...allCourt, ...appendedCourts } }));
  },
  appendBulkRequestCertificates: requestCertificates => {
    const newRequestCertificates: State['allRequestCertificates'] = {};
    requestCertificates.forEach(p => {
      newRequestCertificates[p.id] = p;
    });
    set(({ allRequestCertificates }) => ({
      allRequestCertificates: { ...allRequestCertificates, ...newRequestCertificates },
    }));
  },
  myNFTs: [],
  allNFTs: {},
  allCollections: {},
  myCollections: {},
  appendCollection: (id, data) => {
    set({
      allCollections: { ...get().allCollections, [id]: data },
    });
  },
  appendToMyCollections(id, collection) {
    set({
      myCollections: { ...get().myCollections, [id]: collection },
    });
  },
  appendNFT: (id, type, data) => {
    let appendedNFTData = { ...(get().allNFTs['' + id] ?? {}) };
    if (type === 'data') {
      appendedNFTData = { ...appendedNFTData, data: data as DataDataType };
    } else if (type === 'bid') {
      appendedNFTData = { ...appendedNFTData, bidHistory: data as DataBidType };
    } else if (type === 'offer') {
      appendedNFTData = { ...appendedNFTData, offerList: data as DataOfferType };
    } else if (type === 'activeOffer') {
      appendedNFTData = { ...appendedNFTData, activeOffer: data as boolean };
    } else if (type === 'price') {
      appendedNFTData = { ...appendedNFTData, priceHistory: data as DataPriceType };
    } else if (type === 'certificate') {
      appendedNFTData = { ...appendedNFTData, certificates: data as DataCertificateType };
    } else if (type === 'certificate-status') {
      appendedNFTData = { ...appendedNFTData, certificateStatus: data as DataCertificateStatusType };
    } else if (type === 'tick') {
      appendedNFTData = { ...appendedNFTData, tick: data as DataTicksType };
    }
    set(({ allNFTs }) => ({ allNFTs: { ...allNFTs, [id]: appendedNFTData } }));
  },
  readNFT: id => get().allNFTs[id] ?? {},
  readCollection: id => get().allCollections[id],
  readRequestCertificate: id => get().allRequestCertificates[id],
  clearAllNFTs: () => set({ allNFTs: {}, lastestNFTsCount: null, commonNFTsCount: null }),
  clearAllCollections: () => set({ allCollections: {}, lastestCollectionsCount: null, commonCollectionsCount: null }),
}));

export default useData;
