import { BigNumberish } from 'ethers';
import { CertificatesListObj } from 'global/interfaces';

const certificateListMap = (certificates: any): CertificatesListObj[] =>
  certificates['Validators'].map((a: BigNumberish, i: number) => ({
    address: a,
    certificateIPFS: certificates['CertificateIPFS'][i],
    isAccepted: certificates['IsAccepted'][i],
    tick:certificates['Tick'],
    requestID: Number(certificates['CertificateRequestsID'][i]?.toString()),
    time: new Date(Number(certificates['issuedtime'][i]?.toString()) * 1000).getTime(),
  }));

export default certificateListMap;
