import { metamask, walletConnect } from 'containers/connect/wallets';
import { Chain } from 'global/interfaces';
import { createElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { polygonRPC } from './RPC';
import PolygonMarketABI from 'libs/polygon/MARKET_ABI.json';
import PolygonNFTABI from 'libs/polygon/NTF_ABI.json';
import PolygonDAOABI from 'libs/polygon/DAO_ABI.json';

const { REACT_APP_POLYGON_MARKET_ADDRESS, REACT_APP_POLYGON_DAO_ADDRESS, REACT_APP_POLYGON_NFT_CREATOR_ADDRESS } =
  process.env;

export const network = {
  marketABI: PolygonMarketABI,
  NFTABI: PolygonNFTABI,
  DAOABI: PolygonDAOABI,
  marketAddress: REACT_APP_POLYGON_MARKET_ADDRESS!,
  NFTAddress: REACT_APP_POLYGON_NFT_CREATOR_ADDRESS!,
  DAOAddress: REACT_APP_POLYGON_DAO_ADDRESS!,
};

export const contractConfig={
  nftConfig:{
    address:network.NFTAddress,
    abi:network.NFTABI
  },
  marketConfig:{
    address:network.marketAddress,
    abi:network.marketABI
  },
  daoConfig:{
    address:network.DAOAddress,
    abi:network.DAOABI
  }
}



const chains: Chain[] = [
  {
    name: createElement(FormattedMessage, { id: 'chain.polygon' }),
    id: 'polygon',
    cid: 80001,
    chainId: '0x13881',
    chainName: 'Mumbai',
    wallets: [metamask('0x13881'), walletConnect],
    rpcUrls: polygonRPC,
    marketABI: PolygonMarketABI,
    NFTABI: PolygonNFTABI,
    DAOABI: PolygonDAOABI,
    marketAddress: REACT_APP_POLYGON_MARKET_ADDRESS!,
    NFTAddress: REACT_APP_POLYGON_NFT_CREATOR_ADDRESS!,
    DAOAddress: REACT_APP_POLYGON_DAO_ADDRESS!,
  },
  // {
  //   name: createElement(FormattedMessage, { id: 'chain.ethereum' }),
  //   id: 'ethereum',
  //   chainId: '0x5',
  //   chainName: 'goerli',
  //   wallets: [metamask('0x5'), walletConnect],
  //   rpcUrls: ethereumRPC,
  //   marketABI: EthereumMarketABI,
  //   NFTABI: EthereumNFTABI,
  //   DAOABI: EthereumDAOABI,
  //   marketAddress: REACT_APP_ETHEREUM_MARKET_ADDRESS!,
  //   NFTAddress: REACT_APP_ETHEREUM_NFT_CREATOR_ADDRESS!,
  //   DAOAddress: REACT_APP_ETHEREUM_DAO_ADDRESS!,
  //   disabled: true,
  // },
];

export default chains;
