const parseDynamicTexts = (text: string, replacedTexts: string[]) => {
  if (!replacedTexts.length && !Array.isArray(replacedTexts)) {
    throw Error('replacedText must be an array');
  }
  let alternateText = text.slice();
  replacedTexts.forEach(el => {
    alternateText = alternateText.replace('%d', el);
  });
  return alternateText;
};

export default parseDynamicTexts;
