import api from 'constants/api';

const { REACT_APP_LUXRARE_BACKEND_URL } = process.env;

const getCryptoPrice = (): Promise<Record<string, string>> =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${REACT_APP_LUXRARE_BACKEND_URL}${api.tokenPrices}`);
      const val = await response.json();
      resolve(val);
    } catch (err) {
      reject({ data: 'Error in fetching data' });
    }
  });

export default getCryptoPrice;
