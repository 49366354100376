import { BigNumberish } from 'ethers';
import { PriceHistoryObj } from 'global/interfaces';
import { weiToETH } from './price';

const historyMap = (history: any): PriceHistoryObj[] => {

 return history['prices'].map((p: BigNumberish, i: number) => ({
    price: weiToETH(p),
    from: history['ownerAdresses'][i],
    to: history['ownerAdresses'][i + 1],
    date: Number(history['transferTime'][i].toString()) * 1000,
  }));

  
};

export default historyMap;
