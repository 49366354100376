import Close from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { ApproveSteps } from 'global/enums';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useData from 'store/data';
import useLoading from 'store/loading';

const ApproveDialog: FC = () => {
  const { isApproveDialogOpen, setIsApproveDialogOpen, approveData, setApproveData } = useData();
  const { loading } = useLoading();
  const { formatMessage } = useIntl();

  const steps = [
    {
      label: formatMessage({ id: 'approve-dialog.step-1-title' }),
      description: formatMessage({ id: 'approve-dialog.step-1-description' }),
    },
    {
      label: formatMessage({ id: 'approve-dialog.step-2-title' }),
      description: formatMessage({ id: 'approve-dialog.step-2-description' }),
    },
  ];

  return (
    <Dialog open={isApproveDialogOpen} onClose={() => setIsApproveDialogOpen(false)}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FormattedMessage id="approve-dialog.title" />
        <IconButton onClick={() => setIsApproveDialogOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={approveData.step} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        {approveData.step === ApproveSteps.CONFIRM && (
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() =>
              approveData?.action
                ? approveData?.action()
                : setApproveData({ ...approveData, step: ApproveSteps.FINISH })
            }
          >
            <FormattedMessage id="approve-dialog.step-1-action" />
          </LoadingButton>
        )}
        {approveData.step === ApproveSteps.FINISH && (
          <>
            <Button variant="contained" onClick={() => setApproveData({ ...approveData, step: ApproveSteps.CONFIRM })}>
              <FormattedMessage id="approve-dialog.back" />
            </Button>
            <LoadingButton loading={loading} variant="contained" onClick={() => approveData?.finalAction!()}>
              <FormattedMessage id="approve-dialog.step-2-action" />
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ApproveDialog;
