import * as React from 'react';
import { Grid, Typography, TypographyProps } from '@mui/material';

interface EventMessageTableProps {
  options: { title: string | React.ReactNode; value: string | React.ReactNode; valueProps?: TypographyProps }[];
}

const EventMessageTable: React.FC<EventMessageTableProps> = ({ options }) => {
  return (
    <>
      {options.map((o, oIndex) => (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography sx={{ fontWeight: 'light' }} variant="body2">
              {o.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" {...(o.valueProps ?? {})}>
              {o.value}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default EventMessageTable;
