import ErrorLayout from 'layouts/error';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import paths from 'router/paths';

const Error500: React.FC = () => {
  const { formatMessage } = useIntl();
  const imageAlt = formatMessage({ id: 'errors.500.title' });

  return (
    <ErrorLayout>
      <Typography
        textAlign={'center'}
        fontSize={{ md: 50, xs: 30 }}
        fontWeight={'bold'}
        mb={8}
        variant={'h2'}
        color="#833089"
      >
        <FormattedMessage id="errors.500.title" />
      </Typography>

      <Box component={'img'} maxWidth={'700px'} width={'90%'} src="/images/errors/500.png" alt={imageAlt} />

      <a href={paths.home}>
        <Button sx={{ minWidth: 280, mt: 8, mb: 5 }} variant="outlined" color="primary">
          <FormattedMessage id="errors.500.action" />
        </Button>
      </a>
    </ErrorLayout>
  );
};

export default Error500;
