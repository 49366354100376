
import React from 'react';
import useData from 'store/data';

const OpenWeb3Modal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const open = useData(state=>state.openConnector)

  React.useEffect(() => {
    open();
  }, []);

  return <>{children}</>;
};

export default OpenWeb3Modal;
