import create from 'zustand';
import { PermissionItem } from 'global/enums';
import { UserPermission } from 'global/interfaces';

interface State {
  permission: UserPermission;
  setPermission: (permissions: UserPermission) => void;
}

const usePermission = create<State>()((set, get) => ({
  permission: { isCourt: PermissionItem.UNKNOWN, isValidator: PermissionItem.UNKNOWN },
  setPermission: permissions => set(() => ({ permission: permissions })),
}));

export default usePermission;
