import { DaoProposals, StatusOrPropesalRequest } from 'global/enums';
import { ProposalType, Tick } from 'global/interfaces';
import { createElement } from 'react';
import { FormattedMessage } from 'react-intl';

export const proposalTypes: ProposalType[] = [
  {
    id: DaoProposals.AddOrDeleteCourts,
    title: createElement(FormattedMessage, { id: 'Court' }),
    definition: createElement(FormattedMessage, { id: 'Titan' }),
  },
  {
    id: DaoProposals.AddOrDeleteOrTickValidator,
    title: createElement(FormattedMessage, { id: 'Validator' }),
    definition: createElement(FormattedMessage, { id: 'Theia' }),
  },
  {
    id: DaoProposals.ChangingParameters,
    title: createElement(FormattedMessage, { id: 'Changing-parameter' }),
    definition: createElement(FormattedMessage, { id: 'Changing-parameter' }),
  },
];

export const tickTypes: Tick[] = [
  {
    id: StatusOrPropesalRequest.ADD,
    title: createElement(FormattedMessage, { id: 'Add' }),
    preposition: createElement(FormattedMessage, { id: 'to' }),
  },
  {
    id: StatusOrPropesalRequest.REMOVE,
    title: createElement(FormattedMessage, { id: 'Remove' }),
    preposition: createElement(FormattedMessage, { id: 'from' }),
  },
  {
    id: StatusOrPropesalRequest.ACTIVE,
    title: createElement(FormattedMessage, { id: 'Active' }),
    preposition: createElement(FormattedMessage, { id: 'in' }),
  },
  {
    id: StatusOrPropesalRequest.BAN,
    title: createElement(FormattedMessage, { id: 'Ban' }),
    preposition: createElement(FormattedMessage, { id: 'in' }),
  },
  {
    id: StatusOrPropesalRequest.DOWNTOSILVER,
    title: createElement(FormattedMessage, { id: 'Down-to-silver' }),
    preposition: createElement(FormattedMessage, { id: 'in' }),
  },
  {
    id: StatusOrPropesalRequest.UPTOGOLDEN,
    title: createElement(FormattedMessage, { id: 'Up-to-golden' }),
    preposition: createElement(FormattedMessage, { id: 'in' }),
  },
];
