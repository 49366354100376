import React from 'react';
import paths from './paths';
import { RouterConfig } from './types';

const config: RouterConfig[] = [
  {
    id: 'home',
    path: paths.home,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/home')),
    hasCustomHeader: false,
  },
  {
    id: 'createNFT',
    path: paths.createNFT,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/create-NFT')),
    hasFooter: false,
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'createCollection',
    path: paths.createCollection,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/create-collection')),
    hasFooter: false,
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'updateNFT',
    path: paths.updateNFT,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/update-NFT')),
    hasFooter: false,
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'updateCollection',
    path: paths.updateCollection,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/update-Collection')),
    hasFooter: false,
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'aboutUs',
    path: paths.aboutUs,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/about-us')),
    hasCustomHeader: false,
  },
  {
    id: 'team',
    path: paths.team,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/team-page')),
    hasCustomHeader: false,
  },
  {
    id: 'terms-and-conditions',
    path: paths.terms,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/terms-&-conditions')),
    hasCustomHeader: false,
  },
  {
    id: 'allNFTs',
    path: paths.allNFTs,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/all-NFTs')),
    hasCustomHeader: false,
  },
  {
    id: 'eachNFT',
    path: paths.eachNFT,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/NFT')),
    hasCustomHeader: false,
  },
  {
    id: 'sellNFT',
    path: paths.sellNFT,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/sell-NFT')),
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'userProfile',
    path: paths.userProfile,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/profile/user')),
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'validatorProfile',
    path: paths.validatorProfile,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/profile/validator')),
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: false,
  },
  {
    id: 'validatorPublicProfile',
    path: paths.validatorPublicProfile,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/profile/public-validator')),
    hasCustomHeader: false,
  },
  {
    id: 'validator',
    path: paths.validator,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/validator')),
    hasCustomHeader: false,
    onlyOnDevelopment: true,
  },
  {
    id: 'court',
    path: paths.court,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/court')),
    hasFooter: false,
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasCustomHeader: true,
  },
  {
    id: 'certificate',
    path: paths.createCertificate,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/create-certificate')),
    middleware: { type: 'need-auth', redirectTo: paths.home },
    hasFooter: true,
    hasCustomHeader: false,
  },
  {
    id: 'show-certificate',
    path: paths.showCertificate,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/show-certificate')),
    hasFooter: true,
    hasCustomHeader: false,
  },
  {
    id: 'categories',
    path: paths.categories,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/categories')),
    hasCustomHeader: false,
  },
  {
    id: 'each-user',
    path: paths.eachUser,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/profile/each-user')),
    hasCustomHeader: false,
  },
  {
    id: 'generate-court-cid',
    path: paths.generateCourtCID,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/generate-court-cid')),
    hasCustomHeader: false,
    onlyOnDevelopment: true,
  },
  {
    id: 'all-collections',
    path: paths.allCollections,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/all-Collections')),
    hasCustomHeader: false,
    onlyOnDevelopment: false,
  },
  {
    id: 'single-collection',
    path: paths.singleCollection,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/single-collection')),
    hasCustomHeader: false,
  }, {
    id: 'auth',
    path: paths.auth,
    element: React.lazy(() => import(/* webpackChunkName: "pages-app" */ 'pages/app/auth')),
    hasCustomHeader: false,
  },
];

export default config;
